export const KEYS = {
  LOGGED_IN_USER: 'loggedInUser',
  TOKEN: 'token',
  USERS: 'UsersKey',
  CLASSES: 'ClassesKey',
  CLASS_GENDER_GROUPS: 'ClassGenderGroupsKey',
  STREAMS: 'StreamsKey',
  CLASS_MEDIUMS: 'ClassMediumsKey',
  CLASS_SECTIONS: 'ClassSectionsKey',
  CITIES: 'CitiesKey',
  STATES: 'StatesKey',
  COUNTRIES: 'CountriesKey',
  DESIGNATIONS: 'DesignationsKey',
  EMPLOYEE_GRADES: 'EmployeeGradesKey',
  EMPLOYMENT_TYPES: 'EmploymentTypesKey',
  GENDERS: 'GendersKey',
  RELATIONS: 'RelationsKey',
  ROLES: 'RolesKey',
  SUBJECTS: 'SubjectsKey',
  WORK_SHIFTS: 'WorkShiftsKey',
  QUALIFICATIONS: 'QualificationsKey',
  FEES_PER_CLASS: 'FeesPerClassKey',
  TEMP_SEARCH_PARAM: 'TempSearchParams',
  LOGO_KEY: 'LogoKey'
};

export const BOX_STYLE = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'auto',
  minWidth: '95%',
  height: 'auto',
  maxHeight: '95%',
  bgcolor: 'background.paper',
  border: '2px solid #727272',
  boxShadow: 24,
  borderRadius: '5px',
  pt: 2,
  px: 4,
  pb: 3
};

export const GRID_STYLE = {
  boxShadow: 1,
  border: 1,
  borderColor: 'primary.light',
  '& .MuiDataGrid-cell:hover': {
    color: 'primary.main'
  },
  '& .MuiTablePagination-displayedRows': {
    marginBottom: '0px'
  },
  '& .css-zylse7-MuiButtonBase-root-MuiIconButton-root': {
    width: '40px'
  },
  '& .css-pdct74-MuiTablePagination-selectLabel': {
    marginBottom: '0px'
  }
};

export const PAGE_SIZE = 10;
export const GALLERY_MAX_IMAGES = 20;

export const PRIVILEGES = {
  PRIVATE: 'private',
  PUBLIC_READ: 'public-read',
  PUBLIC_READ_WRITE: 'public-read-write',
  AWS_EXEC_READ: 'aws-exec-read',
  AUTHENTICATED_READ: 'authenticated-read',
  BUCKET_OWNER_READ: 'bucket-owner-read',
  BUCKET_OWNER_FULL_CONTROL: 'bucket-owner-full-control',
  LOG_DELIVERY_WRITE: 'log-delivery-write'
};

export const feesCategories = [
  'Admission Fees',
  'Registration Fees',
  'Academic/Tuition Fees',
  'Examination Fees',
  'Bus Fees',
  'Laboratory Fees',
  'Practical Fees',
  'Library Fees',
  'Building Fund Fees',
  'Maintenance Fees',
  'Sports Fees',
  'Cultural Activity Fees',
  'Field Trip Fees',
  'Club Fees',
  'Parking Fees',
  'Hostel Fees',
  'Mess Fees',
  'Uniform Fees',
  'Book Fees',
  'Stationery Fees',
  'Late Payment Fees',
  'ID Card Fees',
  'Medical Fees',
  'Extra Coaching Fees',
  'Alumni Fees',
  'Certification Fees',
  'Donation Fees',
  'Security Deposit',
  'Content Subscription Fees',
  'Online Learning Fees',
  'Miscellaneous Fees'
];

export const paymentFrequency = ['Weekly', 'Bi-Weekly', 'Monthly', 'Quarterly', 'Half-Yearly', 'Annually'];

export const paymentMethod = [
  'Cash',
  'Debit Card',
  'Credit Card',
  'UPI',
  'Digital Wallet',
  'Internet Banking',
  'Other'
];

export const govIdTypes = [
  'Aadhar',
  'Voter ID',
  'Pan Card',
  'License',
  'Passport',
  'Ration Card',
  'Electricity Bill',
  'Telephone Bill',
  'Bank Passbook',
  'Driver License',
  'Social Security Card',
  'Other'
];

export const categories = [
  'ST',
  'SC',
  'OBC (Non-Creamy Layer)',
  'OBC (Creamy Layer)',
  'General',
  'EWS (Economically Weaker Section)',
  'Other'
];

export const genders = ['Male', 'Female', 'Other'];

export const isDevMode = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

export const baseURL = isDevMode ? process.env.REACT_APP_BASE_URL_LOCAL : process.env.REACT_APP_BASE_URL;

export const ifscCodeRegex = /^[a-zA-Z]{4}0[a-zA-Z0-9]{6}$/;
