import React, { Fragment } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { UsersAPI } from '../../../apis';
import './Sidebar.css';

const Sidebar = () => {
  const history = useHistory();

  async function SignOut() {
    await UsersAPI.logout();
    history.push('/');
  }

  return (
    <Fragment>
      <nav className='navbar-adm d-flex flex-column' style={{ height: '98vh', overflowY: 'auto' }}>
        <ul className='navbar-nav d-flex flex-column w-100'>
          <li className='nav-item'>
            <Link className='nav-link text-bg-light p-2 m-1' to='/admin/users'>
              User Registration
            </Link>
          </li>
          <li className='nav-item'>
            <Link className='nav-link text-bg-light p-2 m-1' to='/admin/fees'>
              Fees Collection
            </Link>
          </li>
          <li className='nav-item'>
            <Link className='nav-link text-bg-light p-2 m-1' to='/admin/feesPerClass'>
              Fees Per Class
            </Link>
          </li>
          {/* <li className='nav-item'>
            <Link className='nav-link text-bg-light p-2 m-1' to='/admin/salary'>
              Salary Distribution
            </Link>
          </li> */}
          <li className='nav-item'>
            <Link className='nav-link text-bg-light p-2 m-1' to='/admin/administration'>
              Administrators
            </Link>
          </li>
          <li className='nav-item'>
            <Link className='nav-link text-bg-light p-2 m-1' to='/admin/facility-infra'>
              Facility & Infrastructure
            </Link>
          </li>
          <li className='nav-item'>
            <Link className='nav-link text-bg-light p-2 m-1' to='/admin/dates'>
              Important Dates
            </Link>
          </li>
          <li className='nav-item'>
            <Link className='nav-link text-bg-light p-2 m-1' to='/admin/news'>
              Latest News
            </Link>
          </li>
          <li className='nav-item'>
            <Link className='nav-link text-bg-light p-2 m-1' to='/admin/about'>
              About
            </Link>
          </li>
          <li className='nav-item'>
            <Link className='nav-link text-bg-light p-2 m-1' to='/admin/contact'>
              School Contact and Social
            </Link>
          </li>
          <li className='nav-item'>
            <Link className='nav-link text-bg-light p-2 m-1' to='/admin/gallery'>
              Gallery
            </Link>
          </li>
          <li className='nav-item'>
            <Link className='nav-link text-bg-light p-2 m-1' to='/admin/logo'>
              School Logo
            </Link>
          </li>
          <li className='nav-item'>
            <Link className='nav-link text-bg-light p-2 m-1' to='/admin/landing'>
              Landing Page Info
            </Link>
          </li>
          <li className='nav-item'>
            <Link className='nav-link text-bg-light p-2 m-1' to='/admin/welcome'>
              Welcome Details
            </Link>
          </li>
          <li className='nav-item'>
            <Link className='nav-link text-bg-light p-2 m-1' to='/admin/admissions'>
              Contact Applications
            </Link>
          </li>
        </ul>
        <button
          className='btn btn-danger p-3 mt-auto'
          onClick={() => {
            SignOut();
          }}
        >
          Sign Out
        </button>
      </nav>
    </Fragment>
  );
};

export default Sidebar;
