import { KEYS } from '../constants';
import { decrypt, isNotBlank } from '../utils';
import { api } from './configs/axiosConfigs';
import { defineCancelApiObject } from './configs/axiosUtils';
import endPoints from './configs/endPoints';
import methods from './configs/methods';

export const UsersAPI = {
  get: async (searchProps = {}, refresh = false, page = 1, limit = 10, cancel = false) => {
    try {
      if (refresh) {
        console.log(refresh);
      }

      const response = await api.request({
        url: endPoints.getAllUsers,
        method: methods.POST,
        data: { ...searchProps, page, limit },
        signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined
      });

      if (response.data.success) {
        const users = response.data.result.users || [];
        const total = response.data.result.total || 0;

        return {
          success: true,
          message: 'Success',
          users,
          total
        };
      }

      return { success: false, message: 'Unknown error, please try again.' };
    } catch (error) {
      console.error('Error in get:', error);
      const message =
        (error?.response && error?.response?.data && error?.response?.data?.message) ||
        'Unexpected error occurred, please contact administrator.';
      return { success: false, message };
    }
  },
  search: async (searchProps = {}, page = 1, limit = 10, cancel = false) => {
    try {
      const response = await api.request({
        url: endPoints.searchUsers,
        method: methods.POST,
        data: { ...searchProps, page, limit },
        signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined
      });
      const users = (response && response.data && response.data.result && response.data.result.users) || [];
      return { success: true, message: response.data.message, users };
    } catch (error) {
      const message =
        (error?.response && error?.response?.data && error?.response?.data?.message) ||
        'Unexpected error ocurred, please contact administrator.';
      return { success: false, message };
    }
  },
  credentials: async (body = {}, cancel = false) => {
    try {
      const response = await api.request({
        url: endPoints.userCredentials,
        method: methods.POST,
        data: body,
        signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined
      });
      console.log('🪵 : credentials: : response:', response);

      const user = (response && response.data && response.data.user) || {};
      console.log('🪵 : credentials: : user:', user);

      if (isNotBlank(user)) {
        const encryptedPassword = user.password;
        const decryptedPassword = decrypt(encryptedPassword);
        console.log('🪵 : credentials: : decryptedPassword:', decryptedPassword);

        if (isNotBlank(decryptedPassword) && decryptedPassword.length > 0) {
          return { success: true, message: response.data.message, user: { ...user, password: decryptedPassword } };
        } else {
          return { success: false, message: 'Unable to fetch credentials, try again.' };
        }
      } else {
        return { success: false, message: 'Unable to fetch credentials, try again.' };
      }
    } catch (error) {
      console.log('🪵 : credentials: : error:', error);
      return { success: false, message: error?.response?.data?.message || 'Unable to fetch credentials, try again.' };
    }
  },
  smsCredentials: async (body = {}, cancel = false) => {
    try {
      const response = await api.request({
        url: endPoints.smsCredentials,
        method: methods.POST,
        data: body,
        signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined
      });

      // console.log('🪵 : credentials: : response:', response);

      if (isNotBlank(response)) {
        return { success: true, message: response.data.message };
      } else {
        return { success: false, message: 'Unable to fetch credentials, try again.' };
      }
    } catch (error) {
      console.log('🪵 : credentials: : error:', error);
      return { success: false, message: error?.response?.data?.message || 'Unable to fetch credentials, try again.' };
    }
  },
  create: async (body = {}, cancel = false) => {
    try {
      const response = await api.request({
        url: endPoints.addUser,
        method: methods.POST,
        data: body,
        signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined
      });

      const user = (response && response.data && response.data.user) || {};
      return { success: isNotBlank(user), message: response.data.message, user };
    } catch (error) {
      return { success: false, message: error?.response?.data?.message || 'Unable to create user, try again.' };
    }
  },
  update: async (body = {}, cancel = false) => {
    try {
      const response = await api.request({
        url: endPoints.updateUser,
        method: methods.PATCH,
        data: body,
        signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined
      });

      const user = (response && response.data && response.data.user) || {};
      return { success: isNotBlank(user), message: response.data.message, user };
    } catch (error) {
      console.log('🪵 : update user: : error:', error);
      return { success: false, message: error?.response?.data?.message || 'Unable to update user, try again.' };
    }
  },
  delete: async (body = {}, cancel = false) => {
    try {
      const response = await api.request({
        url: endPoints.deleteUser,
        method: methods.DELETE,
        data: body,
        signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined
      });

      const user = (response && response.data && response.data.data) || {};
      return { success: isNotBlank(user), message: response.data.message, user };
    } catch (error) {
      return { success: false, message: error?.response?.data?.message || 'Unable to delete user, try again.' };
    }
  },
  login: async (body = {}, cancel = false) => {
    try {
      const response = await api.request({
        url: endPoints.loginUser,
        method: methods.POST,
        data: body,
        signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined
      });

      const user = (response && response.data && response.data.user) || {};
      const authToken = response.headers.get('authorization');

      if (isNotBlank(user) && isNotBlank(authToken)) {
        localStorage.setItem(KEYS.LOGGED_IN_USER, JSON.stringify(user));
        localStorage.setItem(KEYS.TOKEN, authToken);

        return { success: true, message: response.data.message, user };
      } else {
        return { success: false, message: 'Authentication failed' };
      }
    } catch (error) {
      console.log('🪵 : login: : error:', error);
      return { success: false, message: error?.response?.data?.message || 'Unable to login, try again.' };
    }
  },
  logout: async (body = {}, cancel = false) => {
    try {
      const response = await api.request({
        url: endPoints.logoutUser,
        method: methods.POST,
        data: body,
        signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined
      });

      localStorage.removeItem(KEYS.TOKEN);
      localStorage.removeItem(KEYS.LOGGED_IN_USER);
      localStorage.removeItem(KEYS.USERS);
      localStorage.removeItem(KEYS.CLASSES);
      localStorage.removeItem(KEYS.CLASS_GENDER_GROUPS);
      localStorage.removeItem(KEYS.STREAMS);
      localStorage.removeItem(KEYS.CLASS_MEDIUMS);
      localStorage.removeItem(KEYS.CLASS_SECTIONS);
      localStorage.removeItem(KEYS.CITIES);
      localStorage.removeItem(KEYS.STATES);
      localStorage.removeItem(KEYS.COUNTRIES);
      localStorage.removeItem(KEYS.DESIGNATIONS);
      localStorage.removeItem(KEYS.EMPLOYEE_GRADES);
      localStorage.removeItem(KEYS.EMPLOYMENT_TYPES);
      localStorage.removeItem(KEYS.GENDERS);
      localStorage.removeItem(KEYS.RELATIONS);
      localStorage.removeItem(KEYS.ROLES);
      localStorage.removeItem(KEYS.SUBJECTS);
      localStorage.removeItem(KEYS.WORK_SHIFTS);
      localStorage.removeItem(KEYS.QUALIFICATIONS);
      localStorage.removeItem(KEYS.FEES_PER_CLASS);
      localStorage.removeItem(KEYS.TEMP_SEARCH_PARAM);

      return { success: true, message: response.data.message };
    } catch (error) {
      console.log('🪵 : login: : error:', error);
      return { success: false, message: error?.response?.data?.message || 'Unable to logout, try again.' };
    }
  }
};

// defining the cancel API object for ProductAPI
const cancelApiObject = defineCancelApiObject(UsersAPI);
