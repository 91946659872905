import React, { Fragment, useEffect, useState } from 'react';
import axios from 'axios';
import { useFormik } from 'formik';
import { Box, Modal } from '@mui/material';
import toast, { Toaster } from 'react-hot-toast';
import './AdminForm.css';

import { admSchema } from '../../../yupSchema/admSchema';
import { dateSchema } from '../../../yupSchema/dateSchema';
import { infraSchema } from '../../../yupSchema/infraSchema';
import { newsSchema } from '../../../yupSchema/newsSchema';
import AdmCard from '../../containers/administration/card/AdmCard';
import WelcomeCard from '../../containers/welcome/card/WelcomeCard';
import InfraCard from '../../containers/infra/card/InfraCard';
import NewsCard from '../../containers/news/card/NewsCard';
import DatesCard from '../../containers/dates/card/DatesCard';
import { aboutSchema } from '../../../yupSchema/aboutSchema';
import { contactSchema } from '../../../yupSchema/contactSchema';
import { welcomeSchema } from '../../../yupSchema/welcomeSchema';
import { logoSchema } from '../../../yupSchema/logoSchema';
import { gallerySchema } from '../../../yupSchema/gallerySchema';
import GalleryCard from '../../containers/gallery/card/GalleryCard';
import AdmissionCard from '../../containers/admission/card/AdmissionCard';
import { admissionsSchema } from '../../../yupSchema/admissionsSchema';
import LoadingIndicator from '../../../helpers/LoadingIndicator';
import { baseURL, BOX_STYLE, GALLERY_MAX_IMAGES } from '../../../constants';

const AdminForm = ({ pageType }) => {
  const [successMessage, setSuccessMessage] = useState({ message: '' });
  const [isSpinner, setSpinner] = useState(false);
  const [isCreate, setCreate] = useState(false);
  const [isEdit, setEdit] = useState(false);
  const [dataWithId, setDataWithId] = useState('');
  const [isDate, setDate] = useState(false);
  const [isAdm, setAdm] = useState(false);
  const [isInfra, setInfra] = useState(false);
  const [isNews, setNews] = useState(false);
  const [isContact, setContact] = useState(false);
  const [isWelcome, setWelcome] = useState(false);
  const [isAbout, setAbout] = useState(false);
  const [isSingle, setSingle] = useState(false);
  const [isGallery, setGallery] = useState(false);
  const [isLogo, setLogo] = useState(false);
  const [isAdmission, setAdmission] = useState(false);
  const [isHidden, setHidden] = useState(false);

  // fields
  const [isImg, setImg] = useState(true);
  const [isTitle, setTitle] = useState(true);
  const [isContent, setContent] = useState(true);
  const [file, setFile] = useState(null);
  const [galleryImages, setGalleryImages] = useState(null);

  let title;
  let initialValues;
  let url = `${baseURL}/api/${pageType}/create-new`;
  if (isEdit) url = `${baseURL}/api/${pageType}/update/${dataWithId._id}`;
  let schema;
  if (pageType === 'adm') {
    title = 'Administration';
    initialValues = {
      author: (dataWithId && dataWithId.author) || '',
      degree: (dataWithId && dataWithId.degree) || '',
      experience: (dataWithId && dataWithId.experience) || '',
      designation: (dataWithId && dataWithId.designation) || ''
    };
    schema = admSchema;
  }

  if (pageType === 'infra') {
    title = 'Infrastucture and Facility';
    initialValues = {
      title: dataWithId?.title || '',
      content: dataWithId?.content || ''
    };
    schema = infraSchema;
  }
  if (pageType === 'dates') {
    title = 'New Dates';
    initialValues = {
      date: dataWithId?.date,
      title: dataWithId?.title || '',
      content: dataWithId?.content || ''
    };
    schema = dateSchema;
  }
  if (pageType === 'news') {
    title = 'All news';
    initialValues = {
      title: dataWithId?.title || '',
      content: dataWithId?.content
    };
    schema = newsSchema;
  }

  if (pageType === 'about') {
    title = 'About';
    initialValues = {
      teachers: dataWithId?.teachers || '',
      students: dataWithId?.students || '',
      courses: dataWithId?.courses || '',
      years: dataWithId?.years || ''
    };
    schema = aboutSchema;
  }
  if (pageType === 'contact') {
    title = 'Contact';
    initialValues = {
      address: dataWithId?.address || '',
      email: dataWithId?.email || '',
      phone: dataWithId?.phone || '',
      fb: dataWithId?.fb || '',
      in: dataWithId?.in || '',
      twt: dataWithId?.twt || ''
    };
    schema = contactSchema;
  }
  if (pageType === 'welcome') {
    title = 'Welcome';
    initialValues = { content: dataWithId?.content || '' };
    schema = welcomeSchema;
  }
  if (pageType === 'logo') {
    title = 'Logo';
    initialValues = { title: dataWithId?.title || '' };
    schema = logoSchema;
  }
  if (pageType === 'gallery') {
    title = 'Gallery';
    initialValues = { title: dataWithId?.title || '' };
    schema = gallerySchema;
  }
  if (pageType === 'admissions') {
    title = 'Admission Request';
    initialValues = {
      fullName: dataWithId?.fullName || '',
      email: dataWithId?.email || '',
      mobile: dataWithId?.mobile || '',
      message: dataWithId?.message || ''
    };
    schema = admissionsSchema;
  }

  let getUrl = `${baseURL}/api/${pageType}/all`;

  const [datas, setData] = useState([]);

  const temp = isWelcome && isSingle && isHidden;
  if (temp) {
    console.info('🪵 : AdminForm : temp:', temp);
  }

  useEffect(() => {
    setSpinner(true);

    if (pageType === 'dates') {
      setDate(true);
      setImg(false);
    } else if (pageType === 'adm') {
      setContent(false);
      setTitle(false);
      setAdm(true);
    } else if (pageType === 'infra') {
      setInfra(true);
    } else if (pageType === 'news') {
      setNews(true);
    } else if (pageType === 'about') {
      setImg(false);
      setContent(false);
      setTitle(false);
      setAbout(true);
      setSingle(true);
    } else if (pageType === 'contact') {
      setImg(false);
      setContent(false);
      setTitle(false);
      setContact(true);
      setSingle(true);
    } else if (pageType === 'welcome') {
      setImg(true);
      setTitle(false);
      setWelcome(true);
      setSingle(true);
    } else if (pageType === 'gallery') {
      setImg(false);
      setTitle(false);
      setContent(false);
      setHidden(false);

      setGallery(true);
    } else if (pageType === 'logo') {
      setTitle(false);
      setContent(false);
      setHidden(false);
      setImg(false);

      setSingle(true);
      setLogo(true);
    } else if (pageType === 'admissions') {
      setAdmission(true);
      setDate(false);
      setImg(false);
      setContent(false);
      setTitle(false);
      setSingle(false);
    }

    axios
      .get(getUrl)
      .then((resp) => {
        // console.log(resp);
        setData(resp.data.data);
        if (
          (pageType === 'contact' || pageType === 'about' || pageType === 'welcome' || pageType === 'logo') &&
          resp.data.data.length > 0
        ) {
          setEdit(true);
          setCreate(true);
          setDataWithId(resp.data.data[0]);
        }
        // console.log(datas);
        setGalleryImages();
        setFile();
        setSpinner(false);
      })
      .catch((e) => {
        toast.error(e.response.data.message);
        console.error('Error ', e);
        setSpinner(false);
      });
  }, [successMessage]);

  const addImage = (e) => {
    const files = e.target.files;
    if (files.length > 0) {
      if (isGallery) {
        setGalleryImages([...files]);
      } else {
        const file = files[0];
        // console.log(file);
        setFile(file);
      }
    }
  };

  const delHandle = (id) => {
    if (window.confirm('Are you sure you want to delete?') === true) {
      setSpinner(true);
      // console.log(id, 'delete Id');
      const deleteUrl = `${baseURL}/api/${pageType}/delete/${id}`;
      axios
        .get(deleteUrl)
        .then((resp) => {
          if (resp.data.success) {
            setSuccessMessage({ message: resp.data.message });
          } else {
            toast.error(resp.message);
          }

          setSpinner(false);
        })
        .catch((e) => {
          toast.error(e.response.data.message);
          console.error('Error', e);
          setSpinner(false);
        });
    }
  };

  const editForm = (id) => {
    setCreate(true);
    setEdit(true);
    const data = datas.filter((x) => x._id === id);
    setDataWithId(data[0]);
  };

  const Formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: schema,
    onSubmit: (values) => {
      setSpinner(true);
      const fd = new FormData();
      if (isImg || isLogo || isGallery) {
        if (isGallery && galleryImages) {
          for (let i = 0; i < galleryImages.length; i++) {
            let fileObject = galleryImages[i];
            fd.append('images', fileObject, fileObject.name);
          }
        } else if (file) {
          fd.append('image', file, file.name);
        }
      }
      if (pageType === 'dates') {
        fd.append('date', values.date);
      }
      if (pageType === 'adm') {
        fd.append('author', values.author);
        fd.append('degree', values.degree);
        fd.append('experience', values.experience);
        fd.append('designation', values.designation);
      } else if (pageType === 'contact') {
        fd.append('address', values.address);
        fd.append('email', values.email);
        fd.append('phone', values.phone);
        fd.append('in', values.in);
        fd.append('fb', values.fb);
        fd.append('twt', values.twt);
      } else if (pageType === 'about') {
        fd.append('teachers', values.teachers);
        fd.append('students', values.students);
        fd.append('courses', values.courses);
        fd.append('years', values.years);
      } else if (pageType === 'gallery') {
        if (datas.length + galleryImages.length > GALLERY_MAX_IMAGES) {
          alert('You can only add 20 images in gallery!');
          setSpinner(false);
          return;
        }
        fd.append('title', values.title);
      } else if (pageType === 'admissions') {
        fd.append('fullName', values.fullName);
        fd.append('email', values.email);
        fd.append('mobile', values.mobile);
        fd.append('message', values.message);
      } else {
        fd.append('title', values.title);
        fd.append('content', values.content);
      }

      if (isEdit) {
        // console.log(fd);

        if (dataWithId) {
          fd.append('id', dataWithId._id);
        }

        axios
          .patch(url, fd)
          .then((resp) => {
            if (resp.data.success) {
              setSuccessMessage({ message: resp.data.message });
              // console.log(successMessage, 'Success Message');
              setCreate(false);
            } else {
              toast.error(resp.message);
            }
            Formik.resetForm();
            setSpinner(false);
            setGalleryImages();
            setFile();
          })
          .catch((err) => {
            toast.error(err.response.data.message);
            console.error(err, 'error');
            setSpinner(false);
          });
      } else {
        axios
          .post(url, fd)
          .then((resp) => {
            if (resp.data.success) {
              setSuccessMessage({ message: resp.data.message });
              // console.log(successMessage, 'Success Message');
              setCreate(false);
            } else {
              toast.error(resp.message);
            }
            Formik.resetForm();
            setSpinner(false);
            setGalleryImages();
            setFile();
          })
          .catch((err) => {
            toast.error(err.response.data.message);
            console.error(err, 'error');
            setSpinner(false);
          });
      }
    }
  });

  const getAddItemView = () => {
    return (
      isCreate && (
        <div
          className='w-100 position-relative'
          style={{ overflowY: 'auto', maxHeight: '780px', paddingRight: '10px' }}
        >
          <div className='container-form-registration m-auto wi-100'>
            <form onSubmit={Formik.handleSubmit} style={{ zIndex: '99' }} className='bg-white' method='POST'>
              {isAdmission && (
                <div className='mb-3'>
                  <label className='form-label'>Full Name</label>
                  <input
                    type='text'
                    name='fullName'
                    className='form-control'
                    value={Formik.values.fullName}
                    onChange={Formik.handleChange}
                    onBlur={Formik.handleBlur}
                    placeholder='Full Name *'
                  />
                  {Formik.touched.fullName && Formik.errors.fullName ? (
                    <p className='form-error'>{Formik.errors.fullName}</p>
                  ) : null}
                </div>
              )}

              {isAdmission && (
                <div className='mb-3'>
                  <label className='form-label'>Email</label>
                  <input
                    type='email'
                    name='email'
                    className='form-control'
                    value={Formik.values.email}
                    onChange={Formik.handleChange}
                    onBlur={Formik.handleBlur}
                    placeholder='Email *'
                  />
                  {Formik.touched.email && Formik.errors.email ? (
                    <p className='form-error'>{Formik.errors.email}</p>
                  ) : null}
                </div>
              )}

              {isAdmission && (
                <div className='mb-3'>
                  <label className='form-label'>Mobile</label>
                  <input
                    type='tel'
                    name='mobile'
                    className='form-control'
                    value={Formik.values.mobile}
                    onChange={Formik.handleChange}
                    onBlur={Formik.handleBlur}
                    placeholder='Mobile *'
                  />
                  {Formik.touched.mobile && Formik.errors.mobile ? (
                    <p className='form-error'>{Formik.errors.mobile}</p>
                  ) : null}
                </div>
              )}

              {isAdmission && (
                <div className='mb-3'>
                  <label className='form-label'>Message</label>
                  <input
                    type='text'
                    name='message'
                    className='form-control'
                    value={Formik.values.message}
                    onChange={Formik.handleChange}
                    onBlur={Formik.handleBlur}
                    placeholder='Message'
                  />
                  {Formik.touched.message && Formik.errors.message ? (
                    <p className='form-error'>{Formik.errors.message}</p>
                  ) : null}
                </div>
              )}

              {isAdm && (
                <div className='mb-3'>
                  <label className='form-label'>Author</label>
                  <input
                    type='text'
                    name='author'
                    className='form-control'
                    value={Formik.values.author}
                    onChange={Formik.handleChange}
                    onBlur={Formik.handleBlur}
                    placeholder='Author *'
                  />
                  {Formik.touched.author && Formik.errors.author ? (
                    <p className='form-error'>{Formik.errors.author}</p>
                  ) : null}
                </div>
              )}

              {isAdm && (
                <div className='mb-3'>
                  <label className='form-label'>Degree</label>
                  <input
                    type='text'
                    name='degree'
                    className='form-control'
                    value={Formik.values.degree}
                    onChange={Formik.handleChange}
                    onBlur={Formik.handleBlur}
                    placeholder='Degree *'
                  />
                  {Formik.touched.degree && Formik.errors.degree ? (
                    <p className='form-error'>{Formik.errors.degree}</p>
                  ) : null}
                </div>
              )}

              {isAdm && (
                <div className='mb-3'>
                  <label className='form-label'>Experience</label>
                  <input
                    type='number'
                    name='experience'
                    className='form-control'
                    value={Formik.values.experience}
                    onChange={Formik.handleChange}
                    onBlur={Formik.handleBlur}
                    placeholder='Experience *'
                  />
                  {Formik.touched.experience && Formik.errors.experience ? (
                    <p className='form-error'>{Formik.errors.experience}</p>
                  ) : null}
                </div>
              )}

              {isAdm && (
                <div className='mb-3'>
                  <label className='form-label'>Designation</label>
                  <input
                    type='text'
                    name='designation'
                    className='form-control'
                    value={Formik.values.designation}
                    onChange={Formik.handleChange}
                    onBlur={Formik.handleBlur}
                    placeholder='Designation *'
                  />
                  {Formik.touched.designation && Formik.errors.designation ? (
                    <p className='form-error'>{Formik.errors.designation}</p>
                  ) : null}
                </div>
              )}

              {isTitle && (
                <div className='mb-3'>
                  <label className='form-label'>Title</label>
                  <input
                    type='title'
                    name='title'
                    className='form-control'
                    value={Formik.values.title}
                    onChange={Formik.handleChange}
                    onBlur={Formik.handleBlur}
                    placeholder='Title *'
                  />
                  {Formik.touched.title && Formik.errors.title ? (
                    <p className='form-error'>{Formik.errors.title}</p>
                  ) : null}
                </div>
              )}

              {isDate && (
                <div className='mb-3'>
                  <label className='form-label'>Date</label>
                  <input
                    type='date'
                    name='date'
                    className='form-control'
                    onChange={Formik.handleChange}
                    onBlur={Formik.handleBlur}
                    placeholder='Image'
                  />
                </div>
              )}

              {isLogo && (
                <Fragment>
                  <div className='row justify-content-around'>
                    <div className='mb-3'>
                      {isEdit && (
                        <div
                          className='p-3 card mt-3 mr-1 shadow-lg p-3 mb-5 bg-black rounded'
                          style={{ width: '350px', overflow: 'hidden' }}
                        >
                          <label className='form-label align-self-center text-secondary'>Logo</label>
                          <div className='d-flex flex-column justify-content-between'>
                            <img
                              src={dataWithId?.imgUrl}
                              style={{ maxWidth: '100%', objectFit: 'contain' }}
                              className='mb-3'
                              alt={'Logo'}
                            />
                          </div>

                          <input
                            type='file'
                            name='image'
                            className='form-control'
                            onChange={(event) => {
                              addImage(event);
                            }}
                          />
                        </div>
                      )}

                      {!isEdit && (
                        <>
                          <label className='form-label'>Choose Image</label>
                          <input
                            type='file'
                            name='image'
                            className='form-control'
                            onChange={(event) => {
                              addImage(event);
                            }}
                          />
                        </>
                      )}
                    </div>
                  </div>
                </Fragment>
              )}

              {isGallery && (
                <div className='mb-3'>
                  {isEdit && <img src={dataWithId?.imgUrl} height='80px' alt={'Image'} />}

                  {((isEdit && isLogo) || !isEdit) && (
                    <>
                      <label className='form-label mb-2'>Choose Images</label>
                      <input
                        multiple={isGallery}
                        type='file'
                        name='image'
                        className='form-control'
                        onChange={addImage}
                      />
                    </>
                  )}
                </div>
              )}

              {isImg && (
                <div className='mb-3'>
                  <>
                    <img src={dataWithId?.imgUrl} height='80px' alt={'Image'} />
                    <label className='form-label'>Image</label>
                    <input multiple={isGallery} type='file' name='image' className='form-control' onChange={addImage} />
                  </>
                </div>
              )}

              {isContent && (
                <div className='mb-3'>
                  <label className='form-label'>Content</label>
                  <textarea
                    type='text'
                    name='content'
                    className='form-control'
                    value={Formik.values.content}
                    onChange={Formik.handleChange}
                    onBlur={Formik.handleBlur}
                    placeholder='Content *'
                  ></textarea>
                  {Formik.touched.content && Formik.errors.content ? (
                    <p className='form-error'>{Formik.errors.content}</p>
                  ) : null}
                </div>
              )}

              {isContact && (
                <Fragment>
                  <div className='mb-3'>
                    <label className='form-label'>Address</label>
                    <input
                      type='text'
                      name='address'
                      className='form-control'
                      value={Formik.values.address}
                      onChange={Formik.handleChange}
                      onBlur={Formik.handleBlur}
                      placeholder='Address *'
                    />
                    {Formik.touched.address && Formik.errors.address ? (
                      <p className='form-error'>{Formik.errors.address}</p>
                    ) : null}
                  </div>
                  <div className='mb-3'>
                    <label className='form-label'>Email</label>
                    <input
                      type='email'
                      name='email'
                      className='form-control'
                      value={Formik.values.email}
                      onChange={Formik.handleChange}
                      onBlur={Formik.handleBlur}
                      placeholder='Email *'
                    />
                    {Formik.touched.email && Formik.errors.email ? (
                      <p className='form-error'>{Formik.errors.email}</p>
                    ) : null}
                  </div>
                  <div className='mb-3'>
                    <label className='form-label'>Phone</label>
                    <input
                      type='text'
                      name='phone'
                      className='form-control'
                      value={Formik.values.phone}
                      onChange={Formik.handleChange}
                      onBlur={Formik.handleBlur}
                      placeholder='Phone *'
                    />
                    {Formik.touched.phone && Formik.errors.phone ? (
                      <p className='form-error'>{Formik.errors.phone}</p>
                    ) : null}
                  </div>
                  <div className='mb-3'>
                    <label className='form-label'>Instagram</label>
                    <input
                      type='text'
                      name='in'
                      className='form-control'
                      value={Formik.values.in}
                      onChange={Formik.handleChange}
                      onBlur={Formik.handleBlur}
                      placeholder='Instagram *'
                    />
                    {Formik.touched.in && Formik.errors.in ? <p className='form-error'>{Formik.errors.in}</p> : null}
                  </div>
                  <div className='mb-3'>
                    <label className='form-label'>Facebook</label>
                    <input
                      type='text'
                      name='fb'
                      className='form-control'
                      value={Formik.values.fb}
                      onChange={Formik.handleChange}
                      onBlur={Formik.handleBlur}
                      placeholder='Facebook *'
                    />
                    {Formik.touched.fb && Formik.errors.fb ? <p className='form-error'>{Formik.errors.fb}</p> : null}
                  </div>
                  <div className='mb-3'>
                    <label className='form-label'>Twitter</label>
                    <input
                      type='text'
                      name='twt'
                      className='form-control'
                      value={Formik.values.twt}
                      onChange={Formik.handleChange}
                      onBlur={Formik.handleBlur}
                      placeholder='Twitter *'
                    />
                    {Formik.touched.twt && Formik.errors.twt ? <p className='form-error'>{Formik.errors.twt}</p> : null}
                  </div>
                </Fragment>
              )}

              {isAbout && (
                <Fragment>
                  <div className='mb-3'>
                    <label className='form-label'>Teachers</label>
                    <input
                      type='number'
                      name='teachers'
                      className='form-control'
                      value={Formik.values.teachers}
                      onChange={Formik.handleChange}
                      onBlur={Formik.handleBlur}
                      placeholder='Teachers *'
                    />
                    {Formik.touched.teachers && Formik.errors.teachers ? (
                      <p className='form-error'>{Formik.errors.teachers}</p>
                    ) : null}
                  </div>
                  <div className='mb-3'>
                    <label className='form-label'>Students</label>
                    <input
                      type='number'
                      name='students'
                      className='form-control'
                      value={Formik.values.students}
                      onChange={Formik.handleChange}
                      onBlur={Formik.handleBlur}
                      placeholder='Students *'
                    />
                    {Formik.touched.students && Formik.errors.students ? (
                      <p className='form-error'>{Formik.errors.students}</p>
                    ) : null}
                  </div>
                  <div className='mb-3'>
                    <label className='form-label'>Courses</label>
                    <input
                      type='number'
                      name='courses'
                      className='form-control'
                      value={Formik.values.courses}
                      onChange={Formik.handleChange}
                      onBlur={Formik.handleBlur}
                      placeholder='Courses *'
                    />
                    {Formik.touched.courses && Formik.errors.courses ? (
                      <p className='form-error'>{Formik.errors.courses}</p>
                    ) : null}
                  </div>
                  <div className='mb-3'>
                    <label className='form-label'>Years</label>
                    <input
                      type='number'
                      name='years'
                      className='form-control'
                      value={Formik.values.years}
                      onChange={Formik.handleChange}
                      onBlur={Formik.handleBlur}
                      placeholder='Years *'
                    />
                    {Formik.touched.years && Formik.errors.years ? (
                      <p className='form-error'>{Formik.errors.years}</p>
                    ) : null}
                  </div>
                </Fragment>
              )}

              {actionButtons()}
            </form>
          </div>
        </div>
      )
    );
  };

  const closeAddUpdateDialog = (event, reason) => {
    if (reason && reason === 'backdropClick') return;
    setCreate(false);
  };

  const getAddItemModal = () => {
    return (
      <Modal
        open={isCreate}
        onClose={closeAddUpdateDialog}
        aria-labelledby='parent-modal-title'
        aria-describedby='parent-modal-description'
      >
        <Box sx={{ ...BOX_STYLE, overflowY: 'auto', maxHeight: '95%' }}>{getAddItemView()}</Box>
      </Modal>
    );
  };

  const getContentViews = () => {
    return (
      <div className='d-flex flex-row flex-wrap w-100 bg-dark' style={{ height: '90vh', overflowY: 'auto' }}>
        {datas &&
          datas.map((data) => (
            <Fragment key={`${data._id}${Math.random()}`}>
              {isWelcome && <WelcomeCard data={data} key={data._id} del={delHandle} edit={editForm} />}
              {isAdm && <AdmCard data={data} key={data._id} del={delHandle} edit={editForm} />}
              {isInfra && <InfraCard data={data} key={data._id} del={delHandle} edit={editForm} />}
              {isNews && <NewsCard data={data} key={data._id} del={delHandle} edit={editForm} />}
              {isGallery && <GalleryCard data={data} key={data._id} del={delHandle} />}
              {isDate && <DatesCard data={data} key={data._id} del={delHandle} edit={editForm} />}
              {isAdmission && <AdmissionCard data={data} key={data._id} del={delHandle} edit={editForm} />}
            </Fragment>
          ))}
      </div>
    );
  };

  const actionButtons = () => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          alignContent: 'center',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <button
          style={{ zIndex: '100', margin: '5px', width: '200px' }}
          className='btn-danger btn btn-sm d-flex flex-row justify-content-center'
          onClick={() => {
            setGalleryImages();
            setFile();
            setCreate(false);
          }}
        >
          Close
        </button>

        <button
          type='submit'
          style={{ zIndex: '100', margin: '5px', width: '200px' }}
          className='btn-primary btn btn-sm d-flex flex-row justify-content-center'
        >
          Submit
        </button>
      </div>
    );
  };

  return (
    <Fragment>
      <h2 className='text-center bg-white' style={{ borderRadius: 2, overflow: 'hidden' }}>
        <span className='text-white bg-info' style={{ paddingLeft: 10, paddingRight: 10 }}>
          {title}
        </span>
      </h2>

      {getAddItemModal()}
      <button onClick={() => setCreate(true)}>Add New</button>
      {getContentViews()}

      <Toaster />
      <LoadingIndicator open={isSpinner} />
    </Fragment>
  );
};

export default AdminForm;
