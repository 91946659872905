import React, { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import IconButton from '@mui/material/IconButton';
import CloseOutlined from '@mui/icons-material/CloseOutlined';
import AlertDialog from '../../../helpers/AlertDialog';
import DeleteIcon from '@mui/icons-material/Delete';
import RestoreIcon from '@mui/icons-material/Restore';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Button, Stack, Divider, Autocomplete, TextField, FormControlLabel, Checkbox } from '@mui/material'; //Autocomplete, TextField
import {
  isBlank,
  isNotBlank,
  autoCompleteCustomStyle,
  dateFromTimestamp,
  generateObjectId,
  timestampFromDate
} from '../../../utils';
import { useFormik } from 'formik';
import { EmptyGridOverlay } from '../../../helpers/EmptyGridOverlay';
import { GRID_STYLE, paymentFrequency, paymentMethod } from '../../../constants';
import { feesCollectionSchema } from '../../../yupSchema/feesCollectionSchema';
import { FeesCollectionAPI } from '../../../apis/FeesCollectionAPI';

const datePickerDateFormat = 'D MMM, YYYY h:mm A';
const updateInitialValues = (currentUser, initialValues) => ({
  _id: initialValues ? initialValues._id : null,
  rowId: generateObjectId(),
  feesType: initialValues ? initialValues.feesType : '',
  description: initialValues ? initialValues.description : '',
  frequency: initialValues ? initialValues.frequency : paymentFrequency?.[paymentFrequency?.length - 1] || 'Annually',
  paidBy: initialValues ? initialValues.paidBy : '',
  collectedBy: initialValues ? initialValues.collectedBy : currentUser._id,
  updatedBy: initialValues ? initialValues.updatedBy : null,
  fees: initialValues ? initialValues.fees : 0,
  concession: initialValues ? initialValues.concession : 0,
  feesAfterConcession: initialValues ? initialValues.feesAfterConcession : 0,
  receiptAmount: initialValues ? initialValues.receiptAmount : 0,
  paidFees: initialValues ? initialValues.paidFees : 0,
  remainingFees: initialValues ? initialValues.remainingFees : 0,
  paymentMethod: initialValues ? initialValues.paymentMethod : paymentMethod?.[0] || 'Cash',
  currency: initialValues ? initialValues.currency : 'INR',
  invoiceId: initialValues ? initialValues.invoiceId : null,
  isRefund: initialValues ? initialValues.isRefund : false,
  platformFees: initialValues ? initialValues.platformFees : 0,
  tax: initialValues ? initialValues.tax : 0,
  createdAt: initialValues ? initialValues.createdAt : timestampFromDate({ format: datePickerDateFormat }),
  updatedAt: initialValues ? initialValues.updatedAt : null
});

const FeesCollectionForm = ({
  selectedUser,
  responseCallBack,
  setLoading,
  currentUser,
  feesCollection,
  feesTypesForClass
}) => {
  const feesTypesToBeAdded = [
    ...new Set([
      ...(isNotBlank(feesTypesForClass) ? feesTypesForClass.map((ff) => ff.feesType) : []),
      'Late Payment Fees',
      'Miscellaneous Fees'
    ])
  ];
  const [showNonEditableWarning, setShowNonEditableWarning] = useState(false);
  const [showClearDialog, setShowClearDialog] = useState(false);
  const [showRemoveFeesTypeDialog, setShowRemoveFeesTypeDialog] = useState(false);
  const [showRemoveReceiptWarning, setShowRemoveReceiptWarning] = useState(false);
  const [showRestoreReceiptWarning, setShowRestoreReceiptWarning] = useState(false);
  const [showCancelDialog, setShowCancelDialog] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [receiptIdToRemoveOrRestore, setReceiptIdToRemoveOrRestore] = useState();
  const [removeFeesTypeAtIndex, setRemoveFeesTypeAtIndex] = useState(-1);
  const [feesRecords, setFeesRecords] = useState((feesCollection && feesCollection?.feesDetail) || []);
  const feesTypeFieldRef = useRef(null);
  const [feesTypeFieldWidth, setFeesTypeFieldWidth] = useState(0);
  const [isSuperAdmin] = useState(currentUser?.role?.role === 'Super Admin');

  useEffect(() => {
    const handleResize = () => {
      if (feesTypeFieldRef.current) {
        setFeesTypeFieldWidth(feesTypeFieldRef.current.clientWidth);
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const filteredFeesRecords = feesRecords.filter(
      (fr) => fr.feetType === 'Academic/Tuition Fees' && isNotBlank(fr.frequency)
    );
    if (isNotBlank(filteredFeesRecords)) {
      const lastRecord = filteredFeesRecords[filteredFeesRecords.length - 1];
      const filteredFrequency = lastRecord.frequency;
      Formik.setFieldValue('frequency', filteredFrequency);
    }
  }, [feesRecords]);

  const Formik = useFormik({
    initialValues: updateInitialValues(currentUser),
    validationSchema: feesCollectionSchema,
    onSubmit: (values) => handleSubmit(values)
  });

  const handleSubmit = async (values) => {
    try {
      if (isBlank(values)) {
        throw Error('Please fill fees details.');
      }

      if (values.feesType === 'Academic/Tuition Fees' && isBlank(values.frequency)) {
        throw Error('Please select the frequency of payment');
      } else if (values.feesType !== 'Academic/Tuition Fees' && isNotBlank(values.frequency)) {
        Formik.setFieldValue('frequency', null);
      }

      const createdAt = timestampFromDate({ format: datePickerDateFormat });
      const collectedBy = currentUser._id;

      const { _id } = values;

      if (isBlank(_id)) {
        delete values._id;
      }

      let newRecords = [];
      if (isBlank(feesRecords)) {
        newRecords = [{ ...values, createdAt, collectedBy }];
      } else {
        if (isNotBlank(_id)) {
          newRecords = feesRecords.map((record) => {
            if (record._id === _id) {
              const updatedAt = timestampFromDate({ format: datePickerDateFormat });
              const updatedBy = currentUser._id;

              return { ...values, updatedAt, updatedBy };
            }

            return record;
          });

          const alreadyExisting = newRecords.find((nr) => nr._id === _id);
          if (isBlank(alreadyExisting)) {
            newRecords = [{ ...values, createdAt, collectedBy }, ...newRecords];
          }
        } else {
          newRecords = feesRecords.map((record) => {
            if (record.rowId === values.rowId) {
              return { ...values, createdAt, collectedBy };
            }

            return record;
          });

          const alreadyExisting = newRecords.find((nr) => nr.rowId === values.rowId);
          if (isBlank(alreadyExisting)) {
            newRecords = [{ ...values, createdAt, collectedBy }, ...newRecords];
          }
        }
      }

      setFeesRecords(newRecords);
      handleReset();
    } catch (error) {
      console.log('🪵 : onSubmit: : error:', error);
      let errorMessage = '';

      if ('message' in error) {
        errorMessage = error.message;
      } else {
        errorMessage = error.response.data.message;
      }

      responseCallBack({ success: false, message: errorMessage });
    }
  };

  const handleReset = useCallback(() => {
    Formik.resetForm({ values: updateInitialValues(currentUser) });
    setReceiptIdToRemoveOrRestore(null);
    setShowClearDialog(false);
  }, [showClearDialog]);

  const handleResetForm = () => {
    setShowClearDialog(true);
  };

  const handleDelete = () => {
    setShowDeleteDialog(true);
  };

  const handleCancel = () => {
    setShowCancelDialog(true);
  };

  const cancelAddFeesAlert = useCallback(
    () => (
      <AlertDialog
        cancelButtonTitle='No'
        continueButtonTitle='Yes'
        title={'Cancel'}
        message={`Are you sure you want to cancel ${selectedUser ? 'updating' : 'adding'} fees details?`}
        showDialog={showCancelDialog}
        setShowDialog={setShowCancelDialog}
        onContinue={continueCancel}
      />
    ),
    [showCancelDialog, setShowCancelDialog, continueCancel]
  );

  const clearAllEntriesAlert = useCallback(
    () => (
      <AlertDialog
        cancelButtonTitle='No'
        continueButtonTitle='Yes'
        title={'Clear'}
        message={'Are you sure you want to clear all the fields?'}
        showDialog={showClearDialog}
        setShowDialog={setShowClearDialog}
        onContinue={handleReset}
        onClose={() => {
          setReceiptIdToRemoveOrRestore(null);
        }}
      />
    ),
    [showClearDialog, setShowClearDialog, handleReset]
  );

  const removeFeesTypeAlert = useCallback(
    () => (
      <AlertDialog
        cancelButtonTitle='No'
        continueButtonTitle='Yes'
        title={'Remove fees type'}
        message={'Are you sure you want to remove the this fees type?'}
        showDialog={showRemoveFeesTypeDialog}
        setShowDialog={setShowRemoveFeesTypeDialog}
        onContinue={continueRemoveFeesType}
        onClose={() => setRemoveFeesTypeAtIndex(-1)}
      />
    ),
    [showRemoveFeesTypeDialog, removeFeesTypeAtIndex, setShowRemoveFeesTypeDialog, continueRemoveFeesType]
  );

  const deleteFeesForClassAlert = useCallback(
    () => (
      <AlertDialog
        cancelButtonTitle='No'
        continueButtonTitle='Yes'
        title={'Delete'}
        message={'Are you sure you want to delete the fees detail for this class?'}
        showDialog={showDeleteDialog}
        setShowDialog={setShowDeleteDialog}
        onContinue={continueDeleteFeesForClass}
      />
    ),
    [showDeleteDialog, setShowDeleteDialog, continueDeleteFeesForClass]
  );

  const removeReceiptAlert = useCallback(
    () => (
      <AlertDialog
        cancelButtonTitle='No'
        continueButtonTitle='Yes'
        title={'Remove'}
        message={'Are you sure you want to remove the receipt?'}
        showDialog={showRemoveReceiptWarning}
        setShowDialog={setShowRemoveReceiptWarning}
        onContinue={continueRemoveReceipt}
        onClose={() => setReceiptIdToRemoveOrRestore(null)}
      />
    ),
    [showRemoveReceiptWarning, setShowRemoveReceiptWarning, continueRemoveReceipt]
  );

  const restoreReceiptAlert = useCallback(
    () => (
      <AlertDialog
        cancelButtonTitle='No'
        continueButtonTitle='Yes'
        title={'Restore'}
        message={'Are you sure you want to restore the receipt?'}
        showDialog={showRestoreReceiptWarning}
        setShowDialog={setShowRestoreReceiptWarning}
        onContinue={continueRestoreReceipt}
        onClose={() => setReceiptIdToRemoveOrRestore(null)}
      />
    ),
    [showRestoreReceiptWarning, setShowRestoreReceiptWarning, continueRestoreReceipt]
  );

  const nonEditableReceiptWarning = useCallback(
    () => (
      <AlertDialog
        cancelButtonTitle='OK'
        cancelOnlyDialog={true}
        title={'Non Editable Receipt'}
        message={'Old receipt can not be edited, please create new entry.'}
        showDialog={showNonEditableWarning}
        setShowDialog={setShowNonEditableWarning}
        onClose={() => setReceiptIdToRemoveOrRestore(null)}
      />
    ),
    [showNonEditableWarning, setShowNonEditableWarning]
  );

  const continueRemoveFeesType = () => {};

  const continueDeleteFeesForClass = () => {};

  const continueCancel = () => {
    setShowCancelDialog(false);
    responseCallBack({
      success: false,
      message: selectedUser ? 'Fees update canceled' : 'Add fees canceled',
      cancel: true
    });
  };

  const verticalDivider = useCallback(() => <Divider orientation='vertical' flexItem />, []);
  const horizontalDivider = useCallback(() => <Divider orientation='horizontal' flexItem />, []);

  const formTitleAndCloseIcon = useCallback(() => {
    let titleValue = 'Fees Collection';

    return (
      <Stack direction='row' spacing={1}>
        <h4
          style={{
            display: 'flex',
            alignContent: 'center',
            justifyContent: 'center',
            alignItems: 'center',
            width: '-webkit-fill-available',
            color: 'gray'
          }}
        >
          {titleValue}
        </h4>

        <IconButton aria-label='delete' color='primary' style={{ width: '40px' }} onClick={handleCancel}>
          <CloseOutlined />
        </IconButton>
      </Stack>
    );
  }, [handleCancel]);

  const concessionPerType = useMemo(() => {
    if (isNotBlank(feesRecords)) {
      const sortedFeesDetail = [...(feesRecords || [])]?.sort((a, b) => {
        const typeCompare = a.feesType.localeCompare(b.feesType);
        if (typeCompare !== 0) return typeCompare;
        return a.timestamp - b.timestamp;
      });

      const concessionForType = {};
      sortedFeesDetail.forEach((itemLocal) => {
        if (isBlank(concessionForType[itemLocal.feesType])) {
          concessionForType[itemLocal.feesType] = itemLocal?.concession || 0;
        }
      });

      return concessionForType;
    }
  }, [feesRecords]);

  const totalFeesPaidPerType = useMemo(() => {
    if (feesCollection && feesCollection?.feesDetail && feesCollection?.feesDetail?.length > 0) {
      const totalFeesPaid = {};
      feesCollection?.feesDetail.forEach((itemLocal) => {
        if (totalFeesPaid[itemLocal.feesType]) {
          totalFeesPaid[itemLocal.feesType] += itemLocal.isRefund ? -itemLocal.receiptAmount : itemLocal.receiptAmount;
        } else {
          totalFeesPaid[itemLocal.feesType] = itemLocal.isRefund ? -itemLocal.receiptAmount : itemLocal.receiptAmount;
        }
      });

      return totalFeesPaid;
    }
  }, [feesCollection?.feesDetail]);

  const getTotalFeesRemainingPerType = useCallback(
    (feesType) => {
      if (isBlank(feesTypesForClass)) {
        return null;
      }

      const totalFeesRemaining = {};
      const oldReceiptForType = feesCollection?.feesDetail?.find((fr) => fr.feesType === feesType);
      if (isBlank(oldReceiptForType)) {
        // No prior receipts, so we initialize remaining fees to the total fee amount per type
        const feesForType = feesTypesForClass.find((ft) => ft.feesType === feesType)?.amount || 0;
        if (feesForType && feesForType > 0) {
          const concession = concessionPerType?.[feesType] || 0;
          totalFeesRemaining[feesType] = feesForType - concession;
          return totalFeesRemaining;
        } else {
          return null;
        }
      }

      if (isBlank(feesCollection?.feesDetail)) {
        return null;
      }

      feesCollection?.feesDetail?.forEach((itemLocal) => {
        const feesForType = feesTypesForClass.find((ft) => ft?.feesType === itemLocal?.feesType)?.amount || 0;
        const paidFeesForType = totalFeesPaidPerType?.[itemLocal?.feesType] || 0;
        const concession = concessionPerType?.[itemLocal?.feesType] || 0;
        const remainingFeesForType = feesForType - paidFeesForType - concession;
        totalFeesRemaining[itemLocal.feesType] = remainingFeesForType;
      });

      return totalFeesRemaining;
    },
    [totalFeesPaidPerType, concessionPerType, feesCollection?.feesDetail, feesTypesForClass]
  );

  const handleCollect = (shouldPrint = false) => {
    try {
      if (isBlank(feesRecords)) {
        throw Error('Please fill fees details.');
      }

      const hasFees = isNotBlank(feesCollection);
      const id = hasFees ? feesCollection._id : null;
      const student = hasFees ? feesCollection.student._id : selectedUser._id;
      const classAttending = hasFees ? feesCollection.classAttending : selectedUser.classAttending._id;
      const classGenderGroup = hasFees ? feesCollection.classGenderGroup : selectedUser.classGenderGroup._id;
      const classMedium = hasFees ? feesCollection.classMedium : selectedUser.classMedium._id;
      const classSection = hasFees ? feesCollection.classSection : selectedUser.classSection._id;
      const classStream = hasFees
        ? feesCollection.classStream
        : (selectedUser.classStream && selectedUser.classStream._id) || null;

      const totalFees = isNotBlank(feesTypesForClass)
        ? feesTypesForClass?.reduce((total, item) => total + item.amount, 0) || 0
        : feesRecords?.reduce((total, item) => total + item.fees, 0) || 0;

      const totalConcession = Object.values(concessionPerType || {}).reduce(
        (total, amount) => total + (amount || 0),
        0
      );

      const totalFeesAfterConcession = totalFees - totalConcession;

      const totalPaidFees = feesRecords.reduce((total, item) => {
        return total + (item?.isRefund ? 0 : item?.receiptAmount || 0);
      }, 0);

      const totalFeesRefund = feesRecords.reduce((total, item) => {
        return total + (item?.isRefund ? item?.receiptAmount || 0 : 0);
      }, 0);

      const totalRemainingFees = totalFeesAfterConcession + totalFeesRefund - totalPaidFees;
      let paidFeesForType = 0;
      let remainingFeesForType = 0;

      const feesDetail = feesRecords.map((fr) => {
        if (fr?._id) return fr; // Return as-is if existing record

        const feesTypeLocal = fr?.feesType || '';

        // Initialize paidFeesForType and remainingFeesForType once
        if (paidFeesForType === 0) {
          paidFeesForType = totalFeesPaidPerType?.[feesTypeLocal] || 0;
        }

        if (remainingFeesForType === 0) {
          const remainingFeesForTypeLocal = getTotalFeesRemainingPerType(feesTypeLocal) || 0;
          remainingFeesForType = remainingFeesForTypeLocal?.[feesTypeLocal] || 0;
        }

        // Calculate net impact of the receiptAmount based on refund status
        const netReceiptAmount = fr.isRefund ? -fr.receiptAmount : fr.receiptAmount;

        // Calculate paidFees and remainingFees for the current record
        const paidFees = parseInt(paidFeesForType + netReceiptAmount) || 0;

        const remainingFees = parseInt(remainingFeesForType - netReceiptAmount) || 0;

        // Update cumulative totals for future records
        paidFeesForType += netReceiptAmount;
        remainingFeesForType -= netReceiptAmount;

        // Return the updated record
        return { ...fr, paidFees, remainingFees };
      });

      const collection = {
        id,
        student,
        classAttending,
        classGenderGroup,
        classMedium,
        classSection,
        classStream,
        totalFees,
        totalConcession,
        totalFeesAfterConcession,
        totalPaidFees,
        totalFeesRefund,
        totalRemainingFees,
        feesDetail
      };

      setTimeout(async () => {
        let response = null;
        setLoading(true);
        response = await FeesCollectionAPI.collect(collection);
        setLoading(false);

        if (response.success) {
          Formik.resetForm();
          responseCallBack({
            success: true,
            message: 'Payment received successfully!',
            feesCollection: isNotBlank(response.feesCollection) ? response.feesCollection[0] : null,
            shouldPrint
          });
        } else {
          throw Error('Unable to save details, please try again!');
        }
      }, 0);
    } catch (error) {
      setLoading(false);
      console.log('🪵 : onSubmit: : error:', error);
      let errorMessage = '';

      if ('message' in error) {
        errorMessage = error.message;
      } else {
        errorMessage = error.response.data.message;
      }

      responseCallBack({ success: false, message: errorMessage });
    }
  };

  const actionButtons = useCallback(
    () => (
      <Stack
        spacing={2}
        direction='row'
        divider={verticalDivider()}
        justifyContent='space-between'
        alignItems='center'
        style={{ width: '100%' }}
      >
        <Stack
          spacing={2}
          direction='row'
          divider={verticalDivider()}
          justifyContent='flex-end'
          alignItems='center'
          style={{ width: '100%' }}
        >
          <Button
            style={{ maxWidth: '150px' }}
            variant='contained'
            color='primary'
            type='button'
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <Button
            style={{ maxWidth: '150px' }}
            variant='contained'
            color='primary'
            type='submit'
            onClick={() => {
              handleCollect();
            }}
          >
            Collect
          </Button>
          <Button
            style={{ maxWidth: '180px' }}
            variant='contained'
            color='primary'
            type='submit'
            onClick={() => {
              handleCollect(true);
            }}
          >
            Collect and Print
          </Button>
        </Stack>
      </Stack>
    ),
    [selectedUser, handleResetForm, handleDelete, handleCancel]
  );

  const numberForFrequency = (value = '') => {
    const frequency = isNotBlank(value) ? value : Formik.values.frequency || '';
    const frequencyMap = {
      Weekly: 52,
      'Bi-Weekly': 12,
      Monthly: 12,
      Quarterly: 3,
      'Half-Yearly': 2,
      Annually: 1
    };

    return frequencyMap[frequency] || 1;
  };

  const getAddUpdateButtonTitle = () => {
    return receiptIdToRemoveOrRestore ? 'Update' : 'Add';
  };

  const feesTypeSelectionSection = () => (
    <form onSubmit={Formik.handleSubmit}>
      <TextField
        id={'_id-textfield'}
        type='number'
        variant='outlined'
        color='secondary'
        label='_id'
        name={'_id'}
        disabled
        hidden
        fullWidth
        value={Formik.values._id || ''}
        onChange={Formik.handleChange}
      />
      <Stack spacing={2} sx={{ marginTop: 1, marginBottom: 4 }}>
        <Stack spacing={2} direction='row' sx={{ marginTop: 1, marginBottom: 4 }}>
          <Autocomplete
            id='feesType-autocomplete'
            sx={{ ...autoCompleteCustomStyle }}
            ref={feesTypeFieldRef}
            required
            fullWidth
            disablePortal
            autoHighlight
            isOptionEqualToValue={(option, value) => option === value || isBlank(value)}
            options={feesTypesToBeAdded}
            value={Formik.values.feesType}
            onChange={(e, value) => {
              Formik.setFieldValue('feesType', value);
              if (isNotBlank(value)) {
                const feesForType = feesTypesForClass.find((ft) => ft.feesType === value);
                if (feesForType && feesForType.amount > 0) {
                  Formik.setFieldValue('fees', feesForType.amount);
                  Formik.setFieldValue('concession', concessionPerType?.[feesForType.feesType] || 0);
                  Formik.setFieldValue(
                    'feesAfterConcession',
                    feesForType.amount - (concessionPerType?.[feesForType.feesType] || 0)
                  );
                  const totalFeesRemainingPerType = getTotalFeesRemainingPerType(value);
                  const remainingFeesAmount = parseInt(totalFeesRemainingPerType?.[feesForType.feesType] || 0);
                  if (value === 'Academic/Tuition Fees') {
                    const frequency = numberForFrequency(Formik?.values?.frequency || 'Annually');
                    const dividedAmount = feesForType.amount / frequency;
                    const receiptAmountToBeCollected =
                      dividedAmount > remainingFeesAmount ? remainingFeesAmount : dividedAmount;
                    Formik.setFieldValue(
                      'receiptAmount',
                      isNaN(receiptAmountToBeCollected) ? '' : Math.round(receiptAmountToBeCollected.toFixed(2))
                    );
                  } else {
                    const receiptAmountToBeCollected =
                      feesForType.amount > remainingFeesAmount ? remainingFeesAmount : feesForType.amount;
                    Formik.setFieldValue(
                      'receiptAmount',
                      isNaN(receiptAmountToBeCollected) ? '' : Math.round(receiptAmountToBeCollected.toFixed(2))
                    );
                  }
                } else {
                  Formik.resetForm({ values: updateInitialValues(currentUser) });
                }
              } else {
                Formik.resetForm({ values: updateInitialValues(currentUser) });
              }
            }}
            onBlur={Formik.handleBlur}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                required
                id='feesType'
                name='feesType'
                type='text'
                variant='outlined'
                color='secondary'
                label='Fees Type'
                error={Formik.touched.feesType && Boolean(Formik.errors.feesType)}
                helperText={Formik.errors.feesType}
              />
            )}
          />

          <Autocomplete
            id='frequency-autocomplete'
            sx={{ ...autoCompleteCustomStyle }}
            fullWidth
            disablePortal
            autoHighlight
            isOptionEqualToValue={(option, value) => option === value || isBlank(value)}
            options={paymentFrequency}
            value={Formik.values.frequency}
            onChange={(e, value) => {
              const feesTypeValue = Formik.values.feesType;
              const feesForType = feesTypesForClass.find((ft) => ft.feesType === feesTypeValue);
              const totalFeesRemainingPerType = getTotalFeesRemainingPerType(feesTypeValue);
              const remainingFeesAmount = parseInt(totalFeesRemainingPerType?.[feesForType.feesType] || 0);
              Formik.setFieldValue('fees', feesForType.amount);

              if (isBlank(value)) {
                if (feesForType) {
                  const receiptAmountToBeCollected = Math.round(
                    remainingFeesAmount < feesForType.amount ? remainingFeesAmount : feesForType.amount
                  );
                  Formik.setFieldValue(
                    'receiptAmount',
                    isNaN(receiptAmountToBeCollected) ? '' : Math.round(receiptAmountToBeCollected.toFixed(2))
                  );
                }
              } else {
                Formik.setFieldValue('frequency', value);

                if (isNotBlank(feesTypeValue) && isNotBlank(feesForType)) {
                  if (feesTypeValue === 'Academic/Tuition Fees') {
                    const frequency = numberForFrequency(value);
                    const dividedAmount = feesForType.amount / frequency;
                    const receiptAmountToBeCollected =
                      dividedAmount > remainingFeesAmount ? remainingFeesAmount : dividedAmount;
                    Formik.setFieldValue(
                      'receiptAmount',
                      isNaN(receiptAmountToBeCollected) ? '' : Math.round(receiptAmountToBeCollected.toFixed(2))
                    );
                  } else {
                    const receiptAmountToBeCollected =
                      feesForType.amount > remainingFeesAmount ? remainingFeesAmount : feesForType.amount;
                    Formik.setFieldValue(
                      'receiptAmount',
                      isNaN(receiptAmountToBeCollected) ? '' : Math.round(receiptAmountToBeCollected.toFixed(2))
                    );
                  }
                }
              }
            }}
            onBlur={Formik.handleBlur}
            disabled={Formik.values.feesType !== 'Academic/Tuition Fees'}
            renderInput={(params) => (
              <TextField
                {...params}
                id='frequency'
                name='frequency'
                type='text'
                variant='outlined'
                color='secondary'
                label='Frequency'
                fullWidth
              />
            )}
          />

          <TextField
            sx={{ ...autoCompleteCustomStyle }}
            id={'fees-textfield'}
            type='number'
            variant='outlined'
            color='secondary'
            label='Fees'
            name={'fees'}
            disabled
            fullWidth
            value={Formik.values.fees.toFixed(2)}
            onChange={Formik.handleChange}
            onBlur={Formik.handleBlur}
            error={Boolean(Formik.touched.fees && Formik.errors.fees)}
            helperText={Formik.errors.fees}
          />

          <TextField
            sx={{ ...autoCompleteCustomStyle }}
            id='concession-textfield'
            type='number'
            variant='outlined'
            color='secondary'
            label='Concession'
            name='concession'
            disabled={
              !isSuperAdmin ||
              isBlank(Formik.values.feesType) ||
              isBlank(Formik.values.fees) ||
              (isNotBlank(concessionPerType?.[Formik?.values?.feesType]) && !isSuperAdmin)
            }
            value={Formik.values.concession}
            onChange={(e) => {
              const value = parseFloat(e.target.value);
              Formik.setFieldValue('concession', isNaN(value) ? '' : value);
              const selectedFeesType = Formik?.values?.feesType;
              if (isNotBlank(selectedFeesType)) {
                const feesForType = feesTypesForClass.find((ft) => ft.feesType === selectedFeesType);
                if (feesForType && feesForType.amount > 0) {
                  const afterDiscount = value > 0 ? feesForType.amount - value : feesForType.amount;
                  Formik.setFieldValue('feesAfterConcession', afterDiscount);
                  const totalFeesRemainingPerType = getTotalFeesRemainingPerType(selectedFeesType);
                  const remainingFeesAmount =
                    parseInt(totalFeesRemainingPerType?.[feesForType.feesType] || 0) - (value > 0 ? value : 0);
                  if (selectedFeesType === 'Academic/Tuition Fees') {
                    const frequency = numberForFrequency();
                    const dividedAmount = afterDiscount / frequency;
                    const receiptAmountToBeCollected =
                      dividedAmount > remainingFeesAmount ? remainingFeesAmount : dividedAmount;
                    Formik.setFieldValue(
                      'receiptAmount',
                      isNaN(receiptAmountToBeCollected) ? '' : Math.round(receiptAmountToBeCollected.toFixed(2))
                    );
                  } else {
                    const receiptAmountToBeCollected =
                      afterDiscount > remainingFeesAmount ? remainingFeesAmount : afterDiscount;
                    Formik.setFieldValue(
                      'receiptAmount',
                      isNaN(receiptAmountToBeCollected) ? '' : Math.round(receiptAmountToBeCollected.toFixed(2))
                    );
                  }
                }
              }
            }}
            onBlur={Formik.handleBlur}
            fullWidth
            inputProps={{
              min: 0,
              max: Formik.values.fees - (totalFeesPaidPerType?.[Formik?.values?.feesType] || 0) // Discount should not be greater than total fees minus paid fees
            }}
            onInput={(e) => {
              let value = parseFloat(e.target.value);
              if (isNaN(value) || value < 0) {
                e.target.value = '';
              } else if (value > Formik.values.fees) {
                // Discount should not be greater than total fees minus paid fees
                // Example Total fees = 100, Paid fees = 70, Discount = 30, Total fees after discount = 70
                // Now if discount is changed to 40 then we will have to refund 10 Rupees
                e.target.value = Formik.values.fees - (totalFeesPaidPerType?.[Formik?.values?.feesType] || 0);
              }
            }}
          />
        </Stack>
        <Stack spacing={2} direction='row' sx={{ marginTop: 1, marginBottom: 4 }}>
          <TextField
            sx={{ ...autoCompleteCustomStyle }}
            id={'feesAfterConcession-textfield'}
            type='number'
            variant='outlined'
            color='secondary'
            label='Fees After Concession'
            name={'feesAfterConcession'}
            disabled
            fullWidth
            value={Formik.values.feesAfterConcession && Formik.values.feesAfterConcession.toFixed(2)}
            onChange={Formik.handleChange}
            onBlur={Formik.handleBlur}
          />

          <TextField
            sx={{ ...autoCompleteCustomStyle }}
            id={'paidFees-textfield'}
            type='number'
            variant='outlined'
            color='secondary'
            label='Paid Fees'
            name={'paidFees'}
            disabled
            fullWidth
            value={Formik.values.paidFees.toFixed(2)}
            onChange={Formik.handleChange}
            onBlur={Formik.handleBlur}
          />

          <TextField
            sx={{ ...autoCompleteCustomStyle }}
            id={'remainingFees-textfield'}
            type='number'
            variant='outlined'
            color='secondary'
            label='Remaining Fees'
            name={'remainingFees'}
            disabled
            fullWidth
            value={Formik.values.remainingFees.toFixed(2)}
            onChange={Formik.handleChange}
            onBlur={Formik.handleBlur}
          />

          <TextField
            sx={{ ...autoCompleteCustomStyle }}
            id={'receiptAmount-textfield'}
            type='number'
            variant='outlined'
            color='secondary'
            label='Receipt Amount'
            name={'receiptAmount'}
            required
            fullWidth
            value={isNaN(Formik.values.receiptAmount) ? '' : Formik.values.receiptAmount}
            onChange={(e) => {
              const value = parseFloat(e.target.value);
              if (isNaN(value) || value < 0) {
                Formik.setFieldValue('receiptAmount', '');
              } else {
                Formik.setFieldValue('receiptAmount', Math.round(value || 0));
              }
            }}
            inputProps={{
              min: 0,
              max: 500000
            }}
            onInput={(e) => {
              let value = parseFloat(e.target.value);
              if (isNaN(value) || value < 0) {
                e.target.value = '';
              } else if (value > 500000) {
                e.target.value = 500000;
              }
            }}
            onBlur={Formik.handleBlur}
            error={Boolean(Formik.touched.receiptAmount && Formik.errors.receiptAmount)}
            helperText={Formik.errors.receiptAmount}
          />
        </Stack>
        <Stack spacing={2} direction='row' sx={{ marginTop: 1, marginBottom: 4 }}>
          <Autocomplete
            id='paymentMethod-autocomplete'
            sx={{ ...autoCompleteCustomStyle }}
            fullWidth
            required
            disablePortal
            autoHighlight
            options={paymentMethod}
            value={Formik.values.paymentMethod}
            isOptionEqualToValue={(option, value) => option === value || isBlank(value)}
            onChange={(e, value) => Formik.setFieldValue('paymentMethod', value)}
            onBlur={Formik.handleBlur}
            renderInput={(params) => (
              <TextField
                {...params}
                id='paymentMethod'
                name='paymentMethod'
                type='text'
                variant='outlined'
                color='secondary'
                label='Payment Method'
                required
                fullWidth
                error={Formik.touched.paymentMethod && Boolean(Formik.errors.paymentMethod)}
                helperText={Formik.errors.paymentMethod}
              />
            )}
          />
          <TextField
            sx={{ ...autoCompleteCustomStyle }}
            id={'paidBy-textfield'}
            type='text'
            variant='outlined'
            color='secondary'
            label='Payer'
            name={'paidBy'}
            value={Formik.values.paidBy}
            onChange={Formik.handleChange}
            onBlur={Formik.handleBlur}
            fullWidth
          />

          <TextField
            sx={{ ...autoCompleteCustomStyle }}
            id={'description-textfield'}
            type='text'
            variant='outlined'
            color='secondary'
            label='Description'
            name={'description'}
            value={Formik.values.description}
            onChange={Formik.handleChange}
            onBlur={Formik.handleBlur}
            multiline
            fullWidth
          />

          <FormControlLabel
            control={
              <Checkbox
                checked={Formik.values.isRefund}
                onChange={(event) => Formik.setFieldValue('isRefund', event.target.checked)}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            }
            label='Refund'
            labelPlacement='end'
          />

          {isNotBlank(receiptIdToRemoveOrRestore) && (
            <Button
              sx={{ height: '55px', maxWidth: actionButtonWidth() }}
              fullWidth
              variant='outlined'
              color='primary'
              type='button'
              onClick={handleResetForm}
            >
              Reset
            </Button>
          )}
          <Button
            sx={{ height: '55px', maxWidth: actionButtonWidth() }}
            fullWidth
            variant='outlined'
            color='primary'
            type='submit'
            onClick={Formik.handleSubmit}
          >
            {getAddUpdateButtonTitle()}
          </Button>
        </Stack>
      </Stack>
    </form>
  );

  const actionButtonWidth = () => {
    if (isNotBlank(receiptIdToRemoveOrRestore)) {
      if (isNotBlank(feesTypeFieldRef) && isNotBlank(feesTypeFieldRef.current)) {
        const returnWidth = isNotBlank(receiptIdToRemoveOrRestore) ? feesTypeFieldWidth / 2 - 8 : feesTypeFieldWidth;
        return `${returnWidth}px`;
      }

      return isNotBlank(receiptIdToRemoveOrRestore) ? '189px' : '395px';
    } else {
      return `${feesTypeFieldWidth}px`;
    }
  };

  const getFullName = useCallback(
    (userObject) =>
      userObject ? `${userObject.firstName || ''} ${userObject.middleName || ''}  ${userObject.lastName || ''}` : '',
    []
  );

  const handleDeleteReceipt = (id) => {
    setReceiptIdToRemoveOrRestore(id);
    setShowRemoveReceiptWarning(true);
  };

  const continueRemoveReceipt = () => {
    if (isNotBlank(feesRecords)) {
      const filteredData = feesRecords.filter((fr) => fr.rowId !== receiptIdToRemoveOrRestore) || [];
      setFeesRecords(filteredData);
    } else {
      setFeesRecords([]);
    }

    setShowRemoveReceiptWarning(false);
    setReceiptIdToRemoveOrRestore(null);
  };

  const handleRestoreReceipt = (id) => {
    setReceiptIdToRemoveOrRestore(id);
    setShowRestoreReceiptWarning(true);
  };

  const continueRestoreReceipt = () => {
    if (isNotBlank(feesRecords)) {
      const restoredData =
        feesRecords.map((fr) => {
          if (fr._id === receiptIdToRemoveOrRestore) {
            const oldRecord = feesCollection.feesDetail.find((detail) => detail._id === receiptIdToRemoveOrRestore);
            return oldRecord;
          }

          return fr;
        }) || [];
      setFeesRecords(restoredData);
    } else {
      setFeesRecords([]);
    }

    setReceiptIdToRemoveOrRestore(null);
    setShowRestoreReceiptWarning(false);
  };

  const CustomDeleteIconButton = ({ onClick }) => (
    <IconButton aria-label='delete' color='error' style={{ width: '25px' }} onClick={onClick}>
      <DeleteIcon />
    </IconButton>
  );

  const CustomRestoreIconButton = ({ onClick }) => (
    <IconButton aria-label='restore' color='info' style={{ width: '25px' }} onClick={onClick}>
      <RestoreIcon />
    </IconButton>
  );

  const CustomCheckMarkIcon = () => (
    <IconButton aria-label='success' color='success' style={{ width: '25px' }}>
      <CheckCircleOutlineIcon />
    </IconButton>
  );

  const getCellIcon = (values) => {
    const { row } = values;

    if (isNotBlank(row._id) && isNotBlank(feesCollection.feesDetail) && isNotBlank(feesRecords)) {
      const oldRecord = feesCollection.feesDetail.find((detail) => detail._id === row._id);
      const newRecord = feesRecords.find((detail) => detail._id === row._id);

      if (isNotBlank(oldRecord) && isNotBlank(newRecord)) {
        if (JSON.stringify(oldRecord) === JSON.stringify(newRecord)) {
          return <CustomCheckMarkIcon />;
        }
      }

      return <CustomRestoreIconButton onClick={() => handleRestoreReceipt(row._id)} />;
    }

    return <CustomDeleteIconButton onClick={() => handleDeleteReceipt(row.rowId)} />;
  };

  const columns = [
    {
      field: 'actions',
      type: 'actions',
      sortable: false,
      headerName: '',
      renderCell: getCellIcon,
      width: 50
    },
    {
      field: 'feesType',
      headerName: 'Type',
      width: 220
    },
    {
      field: 'frequency',
      sortable: false,
      width: 150,
      headerName: 'Frequency'
    },
    {
      field: 'fees',
      sortable: false,
      width: 150,
      valueGetter: (params) => params.row.fees && params.row.fees.toFixed(2),
      headerName: 'Fees'
    },
    {
      field: 'concession',
      sortable: false,
      headerName: 'Concession',
      valueGetter: (params) => params.row.concession && params.row.concession.toFixed(2),
      width: 100
    },
    {
      field: 'feesAfterConcession',
      sortable: false,
      headerName: 'Fees After Concession',
      valueGetter: (params) => params.row.feesAfterConcession && params.row.feesAfterConcession.toFixed(2),
      width: 200
    },
    {
      field: 'receiptAmount',
      sortable: false,
      width: 150,
      headerName: 'Receipt Amount',
      valueGetter: (params) => params.row.receiptAmount
    },
    {
      field: 'isRefund',
      sortable: false,
      width: 150,
      headerName: 'Receipt Type',
      renderCell: (params) => {
        if (params.row.isRefund) {
          return <div style={{ color: 'red', fontWeight: 'bolder' }}>{'Refund Payment'}</div>;
        }

        return <div style={{ color: 'green', fontWeight: '500' }}>{'Received Payment'}</div>;
      }
    },
    {
      field: 'paidFees',
      sortable: false,
      width: 150,
      valueGetter: (params) => params.row.paidFees && params.row.paidFees.toFixed(2),
      headerName: 'Paid'
    },
    {
      field: 'remainingFees',
      sortable: false,
      width: 150,
      valueGetter: (params) => params.row.remainingFees && params.row.remainingFees.toFixed(2),
      headerName: 'Remaining'
    },
    {
      field: 'paidBy',
      width: 250,
      headerName: 'Payer',
      valueGetter: (params) => params.row.paidBy || '-'
    },
    {
      field: 'collectedBy',
      width: 250,
      headerName: 'Collector',
      valueGetter: (params) => getFullName(params.row.collectedBy)
    },
    {
      field: 'createdAt',
      width: 250,
      headerName: 'Date',
      valueGetter: (params) =>
        dateFromTimestamp({ timestamp: params.row.createdAt, format: 'D MMM, YYYY h:mm A', isString: true })
    },
    {
      field: 'updatedBy',
      width: 250,
      headerName: 'Updater',
      valueGetter: (params) => {
        if (params.row.createdAt === params.row.updatedAt) {
          return '-';
        }
        return params.row.updatedAt > 0 ? getFullName(params.row.collectedBy) : '-';
      }
    },
    {
      field: 'updatedAt',
      width: 250,
      headerName: 'Update Date',
      valueGetter: (params) => {
        if (params.row.createdAt === params.row.updatedAt) {
          return '-';
        }
        const dateParams = {
          timestamp: params.row.updatedAt,
          format: 'D MMM, YYYY h:mm A',
          isString: true
        };
        return params.row.updatedAt > 0 ? dateFromTimestamp(dateParams) : '-';
      }
    }
  ];

  const onClickRow = ({ row }) => {
    if (row._id) {
      setShowNonEditableWarning(true);
    } else {
      setReceiptIdToRemoveOrRestore(row._id || row.rowId);
      Formik.resetForm({ values: row });
    }
  };

  const feesReceipts = useCallback(() => {
    return (
      <div style={{ width: '100%' }}>
        <DataGrid
          getRowId={(row) => row._id || row.rowId}
          slots={{ noRowsOverlay: EmptyGridOverlay }}
          disableSelectionOnClick
          disableColumnMenu
          disableColumnSelector
          rows={feesRecords}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 13
              }
            }
          }}
          onRowClick={onClickRow}
          pageSizeOptions={[13]}
          sx={{ ...GRID_STYLE, minHeight: 200, maxHeight: 500 }}
        />
      </div>
    );
  }, [feesRecords, receiptIdToRemoveOrRestore]);

  return (
    <>
      <Stack spacing={2} direction='column' divider={horizontalDivider()} justifyContent='space-between'>
        {formTitleAndCloseIcon()}
        <div
          style={{
            overflowY: 'auto',
            height: 'auto',
            maxHeight: '90%',
            paddingRight: '10px'
          }}
        >
          {feesTypeSelectionSection()}
          {feesReceipts()}
        </div>
        {actionButtons()}
      </Stack>

      {cancelAddFeesAlert()}
      {clearAllEntriesAlert()}
      {removeFeesTypeAlert()}
      {removeReceiptAlert()}
      {restoreReceiptAlert()}
      {nonEditableReceiptWarning()}
      {deleteFeesForClassAlert()}
    </>
  );
};

export default FeesCollectionForm;
