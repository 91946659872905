/* eslint-disable indent */
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import * as XLSX from 'xlsx';
import { DataGrid } from '@mui/x-data-grid';
import toast, { Toaster } from 'react-hot-toast';
import LoadingIndicator from '../../../helpers/LoadingIndicator';
import { Box, Modal, Button, Stack, TextField, Autocomplete } from '@mui/material';
import UserRegistrationForm from './UserRegistrationForm';
import { loadModelData } from './LoadModelData';
import { UsersAPI } from '../../../apis';
import {
  getCurrentLoggedInUser,
  isNotBlank,
  autoCompleteCustomStyle,
  dateFromTimestamp,
  isBlank
} from '../../../utils';
import { BOX_STYLE, genders, GRID_STYLE } from '../../../constants';
import CloseOutlined from '@mui/icons-material/CloseOutlined';
import IconButton from '@mui/material/IconButton';
import { useHistory } from 'react-router-dom';
import { EmptyGridOverlay } from '../../../helpers/EmptyGridOverlay';
import ProfileImage from '../../../components/UI/profile-image';

const UserRegistration = () => {
  const history = useHistory();

  const [teacherRole, setTeacherRole] = useState();
  const [studentRole, setStudentRole] = useState();
  const [otherRole, setOtherRole] = useState();
  const [actionRole, setActionRole] = useState();
  const [currentUser, setCurrentUser] = useState();
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [editUser, setEditUser] = useState();
  const [showAddUpdateUserDialog, setShowAddUpdateUserDialog] = useState(false);
  const [showExportUsersDialog, setShowExportUsersDialog] = useState(false);
  const [exportInProgress, setExportInProgress] = useState(false);
  const [modelData, setModelData] = useState({});
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [rowCount, setRowCount] = useState(0);

  const [searchString, setSearchString] = useState();
  const [selectedRole, setSelectedRole] = useState(null);
  const [exportUsersRole, setExportUsersRole] = useState('all');
  const [selectedGender, setSelectedGender] = useState(null);
  const [selectedClass, setSelectedClass] = useState(null);
  const [selectedStream, setSelectedStream] = useState(null);
  const [selectedMedium, setSelectedMedium] = useState(null);
  const [selectedSection, setSelectedSection] = useState(null);
  const [selectedGenderGroup, setSelectedGenderGroup] = useState(null);
  const [searchParams, setSearchParams] = useState(null);

  const defaultFieldWidth = 200;
  const roles = modelData.roles;
  const classes = modelData.classes;
  const classGenderGroups = modelData.classGenderGroups;
  const mediums = modelData.classMediums;
  const sections = modelData.classSections;
  const streams = modelData.streams;
  const class11 = classes && classes.find((c) => c.class === '11th Grade');
  const class12 = classes && classes.find((c) => c.class === '12th Grade');

  const columns = [
    {
      field: 'image',
      headerName: 'Profile',
      sortable: false,
      width: 90,
      renderCell: (params) => <ProfileImage src={params.row.image} alt={params.row.firstName} />
    },
    {
      field: 'fullName',
      headerName: 'Full name',
      valueGetter: (params) =>
        `${params.row.firstName || ''} ${params.row.middleName || ''}  ${params.row.lastName || ''}`,
      editable: false,
      width: 250
    },
    {
      field: 'email',
      headerName: 'Email',
      editable: false,
      width: 200
    },
    {
      field: 'role',
      headerName: 'Role',
      valueGetter: (params) => `${params.row.role.role || ''}`,
      editable: false,
      width: 130
    },
    {
      field: 'classAttending',
      headerName: 'Class',
      valueGetter: (params) => (params.row.classAttending && `${params.row.classAttending.class || ''}`) || '',
      editable: false,
      width: 180
    },
    {
      field: 'shift',
      headerName: 'Shift',
      valueGetter: (params) =>
        (params.row.shift &&
          `${params.row.shift.shift || ''} ${params.row.shift.startTime || ''} - ${params.row.shift.endTime || ''}`) ||
        '',
      editable: false,
      width: 250
    }
  ];

  useEffect(() => {
    loadData();
    loadCurrentUser();
  }, []);

  useEffect(() => {
    loadUsers();
  }, [page, pageSize, searchParams]);

  const loadCurrentUser = () => {
    setTimeout(async () => {
      try {
        const user = await getCurrentLoggedInUser();
        if (isNotBlank(user)) {
          setCurrentUser(user);
        } else {
          await UsersAPI.logout();
          history.push('/');
        }
      } catch (error) {
        console.log('🪵 : useEffect : error:', error);
        await UsersAPI.logout();
        history.push('/');
      }
    }, 0);
  };

  const loadData = () => {
    setTimeout(async () => {
      try {
        setLoading(true);
        const data = await loadModelData();
        setModelData(data);
        await loadUsers();
        parseRoles(data);
        setLoading(false);
      } catch (error) {
        console.log('🪵 : loadData : error:', error);
        setLoading(false);
      }
    }, 0);
  };

  const loadUsers = useCallback(
    async (refresh = false) => {
      setLoading(true);
      const response = await UsersAPI.get(searchParams, refresh, page + 1, pageSize);
      setUsers(response.users);
      setRowCount(response.total);
      setLoading(false);
    },
    [page, pageSize, searchParams]
  );

  const parseRoles = (data) => {
    if (isNotBlank(data) && isNotBlank(data.roles)) {
      const teacherRoleLocal = data.roles.filter((role) => role.role === 'Teacher')[0];
      const studentRoleLocal = data.roles.filter((role) => role.role === 'Student')[0];
      const otherRoles = data.roles.filter((role) => role.role !== 'Student' && role.role !== 'Teacher');
      const otherRoleLocal = isNotBlank(otherRoles) ? otherRoles[otherRoles.length - 1] : null;

      setStudentRole(studentRoleLocal);
      setTeacherRole(teacherRoleLocal);
      setOtherRole(otherRoleLocal);
    }
  };

  const onClickRegisterStudent = () => {
    openAddUpdateUserDialog(studentRole);
  };

  const onClickRegisterTeacher = () => {
    openAddUpdateUserDialog(teacherRole);
  };

  const onClickRegisterOthers = () => {
    openAddUpdateUserDialog(otherRole);
  };

  const onClickRefresh = () => {
    setSearchString(null);
    setSelectedClass(null);
    setSelectedStream(null);
    setSelectedMedium(null);
    setSelectedSection(null);
    setSelectedGenderGroup(null);
    setSearchParams(null);
    loadUsers(true);
  };

  const onClickExportUsers = () => {
    openExportUsersDialog();
  };

  const onClickRow = (content) => {
    const user = content.row;
    openAddUpdateUserDialog(user.role, user);
  };

  const onClickSearch = () => {
    let searchParamsLocal = {};
    searchParamsLocal = searchString ? { ...searchParamsLocal, query: searchString } : searchParamsLocal;
    searchParamsLocal = selectedRole ? { ...searchParamsLocal, role: selectedRole } : searchParamsLocal;
    searchParamsLocal = selectedGender ? { ...searchParamsLocal, gender: selectedGender } : searchParamsLocal;
    searchParamsLocal = selectedClass ? { ...searchParamsLocal, classAttending: selectedClass } : searchParamsLocal;
    searchParamsLocal = selectedMedium ? { ...searchParamsLocal, classMedium: selectedMedium } : searchParamsLocal;
    searchParamsLocal = selectedSection ? { ...searchParamsLocal, classSection: selectedSection } : searchParamsLocal;
    searchParamsLocal = selectedStream ? { ...searchParamsLocal, classStream: selectedStream } : searchParamsLocal;
    searchParamsLocal = selectedGenderGroup
      ? { ...searchParamsLocal, classGenderGroup: selectedGenderGroup }
      : searchParamsLocal;
    setSearchParams(searchParamsLocal);
  };

  const openAddUpdateUserDialog = (role, user = null) => {
    setActionRole(role);
    setEditUser(user);
    setShowAddUpdateUserDialog(true);
  };

  const closeAddUpdateUserDialog = (event, reason) => {
    if (reason && reason === 'backdropClick') return;
    setShowAddUpdateUserDialog(false);
    setActionRole(null);
    setEditUser(null);
  };

  const openExportUsersDialog = () => {
    setShowExportUsersDialog(true);
  };

  const closeExportUsersDialog = (event, reason) => {
    if (reason && reason === 'backdropClick') return;
    setShowExportUsersDialog(false);
  };

  const responseCallBack = (response = { success: false, message: '' }) => {
    try {
      if (response.success) {
        loadUsers(true);
        closeAddUpdateUserDialog();
        setEditUser(null);
        toast.success(response.message);
      } else {
        toast.error(response.message);
        if (response.registrationCanceled) {
          closeAddUpdateUserDialog();
        }
      }
    } catch (error) {
      console.error('Error in responseCallBack:', error);
      toast.error('An unexpected error occurred.');
    } finally {
      setLoading(false);
    }
  };

  const dataGrid = () => (
    <div style={{ minHeight: 250, width: '100%' }}>
      <DataGrid
        getRowId={(row) => row._id.valueOf()}
        slots={{ noRowsOverlay: EmptyGridOverlay }}
        rows={users || []}
        columns={columns}
        paginationModel={{ page, pageSize }}
        onPaginationModelChange={(model) => {
          setPage(model.page);
          setPageSize(model.pageSize);
        }}
        pagination
        paginationMode='server'
        onRowClick={onClickRow}
        pageSizeOptions={[10, 25, 50]}
        rowCount={rowCount}
        sx={GRID_STYLE}
      />
    </div>
  );

  const actionButtons = () => (
    <div
      className='d-flex flex-row justify-content-between'
      style={{ height: '60px', marginTop: '10px', marginBottom: '10px' }}
    >
      <Button style={{ marginLeft: '0px', marginRight: '10px' }} variant='contained' onClick={onClickRegisterStudent}>
        Register Student
      </Button>
      <Button style={{ marginLeft: '10px', marginRight: '10px' }} variant='contained' onClick={onClickRegisterTeacher}>
        Register Teacher
      </Button>
      <Button style={{ marginLeft: '10px', marginRight: '10px' }} variant='contained' onClick={onClickRegisterOthers}>
        Register Others
      </Button>
      <Button style={{ marginLeft: '10px', marginRight: '0px' }} variant='contained' onClick={onClickRefresh}>
        Refresh Users
      </Button>
      <Button style={{ marginLeft: '10px', marginRight: '0px' }} variant='contained' onClick={onClickExportUsers}>
        Export Users
      </Button>
    </div>
  );

  const addUpdateUserDialog = () => {
    return (
      <Modal
        open={showAddUpdateUserDialog}
        onClose={closeAddUpdateUserDialog}
        aria-labelledby='parent-modal-title'
        aria-describedby='parent-modal-description'
      >
        <Box sx={{ ...BOX_STYLE, overflowY: 'auto', maxHeight: '95%' }}>
          <UserRegistrationForm
            editUser={editUser}
            actionRoleParam={actionRole}
            modelData={modelData}
            responseCallBack={responseCallBack}
            setLoading={setLoading}
            currentUser={currentUser}
          />
        </Box>
      </Modal>
    );
  };

  const subjectExpertiseString = (subjectExpertise) => {
    if (isNotBlank(subjectExpertise)) {
      return subjectExpertise.map((subject) => subject.subject).join(', ');
    }

    return '';
  };

  const givIdAndTypeString = (govIds) => {
    if (isNotBlank(govIds)) {
      return govIds.map((govId) => `${govId.govIdType}: ${govId.govIdNumber}`).join(', ');
    }

    return '';
  };

  const exportToExcel = async () => {
    if (isBlank(exportUsersRole)) {
      return;
    }

    try {
      setExportInProgress(true);
      const studentRoleLocal = roles?.filter((role) => role.role === 'Student')[0];
      const exportParams = { role: exportUsersRole || studentRoleLocal?._id || '' };
      const response = await UsersAPI.get(exportUsersRole === 'all' ? {} : exportParams, true, 1, 10000);
      const usersToBeExported = response.users || [];
      const userData = usersToBeExported.map((user) => ({
        firstName: user?.firstName || '',
        middleName: user?.middleName || '',
        lastName: user?.lastName || '',
        mobile: user?.mobile || '',
        email: user?.email || '',
        role: user?.role.role || '',
        gender: user?.gender || '',
        category: user?.category || '',
        dob: dateFromTimestamp({ timestamp: user?.dob, format: 'DD MMM YYYY', isString: true }) || '',
        address: user?.address || '',
        city: user?.city?.city || '',
        state: user?.state?.state || '',
        country: user?.country?.country || '',
        childId: user?.childId || '',
        admissionNo: user?.admissionNo || '',
        penNo: user?.penNo || '',
        parentOrSpouseName: user?.parentOrSpouseName || '',
        parentOrSpouseContact: user?.parentOrSpouseContact || '',
        emergencyContactRelation: user?.emergencyContactRelation?.relation || '',
        fatherName: user?.fatherName || '',
        fatherContact: user?.fatherContact || '',
        motherName: user?.motherName || '',
        motherContact: user?.motherContact || '',
        joiningDate: dateFromTimestamp({ timestamp: user?.joiningDate, format: 'DD MMM YYYY', isString: true }) || '',
        classAttending: user?.classAttending?.class || '',
        classGenderGroup: user?.classGenderGroup?.classGenderGroup || '',
        classMedium: user?.classMedium?.classMedium || '',
        classSection: user?.classSection?.classSection || '',
        classStream: user?.classStream?.stream || '',
        classesTeaching: user?.classesTeaching?.class || '',
        classTeacherOfClass: user?.classTeacherOfClass?.class || '',
        subjectExpertise: subjectExpertiseString(user?.subjectExpertise) || '',
        yearsOfExperience: user?.yearsOfExperience || '',
        qualification: user?.qualification?.qualification || '',
        govIds: givIdAndTypeString(user?.govIds) || '',
        bankDetails: user?.bankDetails?.bankName || '',
        employmentGrade: user?.employmentGrade?.employmentGrade || '',
        employmentType: user?.employmentType?.employmentType || '',
        employmentDesignation: user?.employmentDesignation?.designation || ''
      }));
      console.log('\n▶️ -> userData -> userData:', userData);

      const workbook = XLSX.utils.book_new();
      const worksheetData = [
        [
          'First name',
          'Middle name',
          'Last name',
          'Mobile',
          'Email',
          'Role',
          'Gender',
          'Category',
          'Dob',
          'Address',
          'City',
          'State',
          'Country',
          'Child id',
          'Admission no',
          'Pen no',
          'Parent or spouse name',
          'Parent or spouse contact',
          'Emergency contact relation',
          'Father name',
          'Father contact',
          'Mother name',
          'Mother contact',
          'Joining date',
          'Class attending',
          'Class gender group',
          'Class medium',
          'Class section',
          'Class stream',
          'Classes teaching',
          'Class teacher of class',
          'Subject expertise',
          'Years of experience',
          'Qualification',
          'Gov ids',
          'Bank details',
          'Employment grade',
          'Employment type',
          'Employment designation'
        ],
        ...userData.map((user) => [
          user?.firstName || '',
          user?.middleName || '',
          user?.lastName || '',
          user?.mobile || '',
          user?.email || '',
          user?.role || '',
          user?.gender || '',
          user?.category || '',
          user?.dob || '',
          user?.address || '',
          user?.city || '',
          user?.state || '',
          user?.country || '',
          user?.childId || '',
          user?.admissionNo || '',
          user?.penNo || '',
          user?.parentOrSpouseName || '',
          user?.parentOrSpouseContact || '',
          user?.emergencyContactRelation || '',
          user?.fatherName || '',
          user?.fatherContact || '',
          user?.motherName || '',
          user?.motherContact || '',
          user?.joiningDate || '',
          user?.classAttending || '',
          user?.classGenderGroup || '',
          user?.classMedium || '',
          user?.classSection || '',
          user?.classStream || '',
          user?.classesTeaching || '',
          user?.classTeacherOfClass || '',
          user?.subjectExpertise || '',
          user?.yearsOfExperience || '',
          user?.qualification || '',
          user?.govIds || '',
          user?.bankDetails || '',
          user?.employmentGrade || '',
          user?.employmentType || '',
          user?.employmentDesignation || ''
        ])
      ];
      const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
      XLSX.utils.book_append_sheet(workbook, worksheet, 'User Records');
      XLSX.writeFile(workbook, 'User_Records.xlsx');
    } catch (error) {
      console.log('\n▶️ -> exportToExcel -> error:', error);
      toast.error('Export failed, please try again!');
    } finally {
      setExportInProgress(false);
    }
  };

  const handleCancelExport = () => {
    setShowExportUsersDialog(false);
    setExportInProgress(false);
    setExportUsersRole(null);
  };

  const exportTitleAndCloseIcon = () => {
    return (
      <Stack direction='row' spacing={1}>
        <h4
          style={{
            display: 'flex',
            alignContent: 'center',
            justifyContent: 'center',
            alignItems: 'center',
            width: '-webkit-fill-available'
          }}
        >
          {exportUsersRole === 'all'
            ? 'Export All'
            : `Export ${
                [...roles, { role: 'All', _id: 'all' }].find((item) => item._id === exportUsersRole)?.role === 'User'
                  ? 'User'
                  : `${
                      [...roles, { role: 'All', _id: 'all' }].find((item) => item._id === exportUsersRole)?.role ||
                      'User'
                    }s`
              }`}
        </h4>

        {exportInProgress && <LoadingIndicator open={exportInProgress} />}

        {!exportInProgress && (
          <IconButton aria-label='delete' color='primary' style={{ width: '40px' }} onClick={handleCancelExport}>
            <CloseOutlined />
          </IconButton>
        )}
      </Stack>
    );
  };

  const exportUsersDialog = () => {
    return (
      <Modal
        open={showExportUsersDialog}
        onClose={closeExportUsersDialog}
        aria-labelledby='parent-modal-title'
        aria-describedby='parent-modal-description'
      >
        <Box sx={{ ...BOX_STYLE, overflowY: 'auto', minWidth: '30%', maxWidth: '30%', maxHeight: '95%' }}>
          {exportTitleAndCloseIcon()}

          {exportInProgress ? (
            <Stack spacing={{ xs: 2 }} direction='row' sx={{ marginTop: 2, marginBottom: 2 }}>
              <LoadingIndicator open={exportInProgress} />
              <h4>Export in progress please wait...</h4>
            </Stack>
          ) : (
            <Stack spacing={{ xs: 2 }} direction='row' sx={{ marginTop: 2, marginBottom: 2 }}>
              {roles && roles.length > 0 && (
                <Autocomplete
                  id='roles-autocomplete-export'
                  options={[...roles, { role: 'All', _id: 'all' }]}
                  getOptionLabel={(option) => option.role || ''}
                  fullWidth
                  sx={{ maxWidth: '250px', ...autoCompleteCustomStyle }}
                  value={
                    roles ? [...roles, { role: 'All', _id: 'all' }].find((item) => item._id === exportUsersRole) : null
                  }
                  onChange={(e, value) => {
                    setExportUsersRole(value && value._id);
                  }}
                  renderInput={(params) => <TextField {...params} label='Export User Type' />}
                />
              )}

              <Button style={{ height: '55px', maxWidth: '250px' }} variant='contained' onClick={exportToExcel}>
                {exportUsersRole === 'all'
                  ? 'Export All'
                  : `Export ${
                      [...roles, { role: 'All', _id: 'all' }].find((item) => item._id === exportUsersRole)?.role ===
                      'User'
                        ? 'User'
                        : `${
                            [...roles, { role: 'All', _id: 'all' }].find((item) => item._id === exportUsersRole)
                              ?.role || 'User'
                          }s`
                    }`}
              </Button>
            </Stack>
          )}
        </Box>
      </Modal>
    );
  };

  const studentRoleSelectedForSearch = () => {
    const selectedRoleLocal = roles.find((item) => item._id === selectedRole);
    return selectedRoleLocal?.role === 'Student';
  };

  const searchOptions = () => (
    <Stack spacing={{ xs: 2 }} direction='row' useFlexGap sx={{ marginTop: 2, marginBottom: 4, flexWrap: 'wrap' }}>
      <TextField
        id='search-student'
        label='Name, Email, Mobile'
        value={searchString || ''}
        onChange={(event) => {
          const value = event.target.value;
          setSearchString(value || '');
        }}
        onKeyPress={(event) => {
          const value = event.target.value;
          const key = event.key;
          if (key === 'Enter' && isNotBlank(value)) {
            onClickSearch();
          }
        }}
        variant='outlined'
        fullWidth
        sx={{ maxWidth: defaultFieldWidth, ...autoCompleteCustomStyle }}
      />
      {roles && roles.length > 0 && (
        <Autocomplete
          id='roles-autocomplete'
          options={roles}
          getOptionLabel={(option) => option.role || ''}
          fullWidth
          sx={{ maxWidth: defaultFieldWidth, ...autoCompleteCustomStyle }}
          value={roles ? roles.find((item) => item._id === selectedRole) : null}
          onChange={(e, value) => {
            setSelectedRole(value && value._id);
          }}
          renderInput={(params) => <TextField {...params} label='Role' />}
        />
      )}
      <Autocomplete
        id='genders-autocomplete'
        options={genders}
        getOptionLabel={(option) => option || ''}
        fullWidth
        sx={{ maxWidth: defaultFieldWidth, ...autoCompleteCustomStyle }}
        value={genders ? genders.find((item) => item === selectedGender) : null}
        onChange={(e, value) => {
          setSelectedGender(value);
        }}
        renderInput={(params) => <TextField {...params} label='Gender' />}
      />
      {classes && studentRoleSelectedForSearch() && (
        <Autocomplete
          id='classAttending-autocomplete'
          options={classes}
          getOptionLabel={(option) => option.class || ''}
          fullWidth
          sx={{ maxWidth: defaultFieldWidth, ...autoCompleteCustomStyle }}
          value={classes ? classes.find((item) => item._id === selectedClass) : null}
          onChange={(e, value) => {
            setSelectedClass(value && value._id);
            setSelectedStream(null);
          }}
          renderInput={(params) => <TextField {...params} label='Class' />}
        />
      )}
      {sections && studentRoleSelectedForSearch() && (
        <Autocomplete
          id='section-autocomplete'
          options={sections}
          getOptionLabel={(option) => option.classSection || ''}
          fullWidth
          sx={{ maxWidth: defaultFieldWidth, ...autoCompleteCustomStyle }}
          value={sections ? sections.find((item) => item._id === selectedSection) : null}
          onChange={(e, value) => setSelectedSection(value && value._id)}
          renderInput={(params) => <TextField {...params} label='Section' />}
        />
      )}
      {mediums && studentRoleSelectedForSearch() && (
        <Autocomplete
          id='medium-autocomplete'
          options={mediums}
          getOptionLabel={(option) => option.classMedium || ''}
          fullWidth
          sx={{ maxWidth: defaultFieldWidth, ...autoCompleteCustomStyle }}
          value={mediums ? mediums.find((item) => item._id === selectedMedium) : null}
          onChange={(e, value) => setSelectedMedium(value && value._id)}
          renderInput={(params) => <TextField {...params} label='Medium' />}
        />
      )}
      {streams &&
        (selectedClass === class11._id || selectedClass === class12._id) &&
        studentRoleSelectedForSearch() && (
          <Autocomplete
            id='streams-autocomplete'
            options={streams}
            getOptionLabel={(option) => option.stream || ''}
            fullWidth
            sx={{ maxWidth: defaultFieldWidth, ...autoCompleteCustomStyle }}
            value={streams ? streams.find((item) => item._id === selectedStream) : null}
            onChange={(e, value) => setSelectedStream(value && value._id)}
            renderInput={(params) => <TextField {...params} label='Stream' />}
          />
        )}

      {classGenderGroups && studentRoleSelectedForSearch() && (
        <Autocomplete
          id='classGenderGroup-autocomplete'
          options={classGenderGroups}
          getOptionLabel={(option) => option.classGenderGroup || ''}
          fullWidth
          sx={{ maxWidth: defaultFieldWidth, ...autoCompleteCustomStyle }}
          value={classGenderGroups ? classGenderGroups.find((item) => item._id === selectedGenderGroup) : null}
          onChange={(e, value) => setSelectedGenderGroup(value && value._id)}
          renderInput={(params) => <TextField {...params} label='Gender Group' />}
        />
      )}
      <Button style={{ height: '55px', maxWidth: '200px' }} variant='contained' onClick={onClickSearch}>
        Search
      </Button>
    </Stack>
  );

  const header = () => {
    return (
      <Stack>
        <h2 style={{ color: 'gray' }}>User Registration</h2>
        {actionButtons()}
        {searchOptions()}
      </Stack>
    );
  };

  return (
    <>
      <section className='p-3 card w-100' style={{ height: '98vh', overflowY: 'auto' }}>
        <article
          className='d-flex flex-column justify-content-between'
          style={{ maxHeight: '98vh', overflowY: 'auto' }}
        >
          {header()}
          {dataGrid()}
          {addUpdateUserDialog()}
          {exportUsersDialog()}
        </article>
      </section>
      <Toaster />
      <LoadingIndicator open={loading} />
    </>
  );
};

export default UserRegistration;
