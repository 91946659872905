import React, { Fragment, useEffect, useState } from 'react';
import LoadingIndicator from '../../../helpers/LoadingIndicator';
import { DataGrid } from '@mui/x-data-grid';
import { Toaster } from 'react-hot-toast';
import { Box, Modal, Button, TextField, Autocomplete } from '@mui/material';
import { UsersAPI } from '../../../apis';
import { autoCompleteCustomStyle, getCurrentLoggedInUser, isNotBlank } from '../../../utils';
import { BOX_STYLE, GRID_STYLE } from '../../../constants';
import { useHistory } from 'react-router-dom';
import { EmptyGridOverlay } from '../../../helpers/EmptyGridOverlay';
import { loadModelData } from '../userRegistration/LoadModelData';
import ProfileImage from '../../../components/UI/profile-image';

const SalaryDistributionForm = () => {
  // const [teacherRole, setTeacherRole] = useState();
  // const [otherRole, setOtherRole] = useState();
  // const [actionRole, setActionRole] = useState();
  // const [currentUser, setCurrentUser] = useState();
  // const [editUser, setEditUser] = useState();

  const history = useHistory();

  const [studentRole, setStudentRole] = useState();
  const [modelData, setModelData] = useState({});
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showAddUpdateUserDialog, setShowAddUpdateUserDialog] = useState(false);
  const [searchString, setSearchString] = useState('');

  const designations = modelData.designations;
  const grades = modelData.employeeGrades;

  const columns = [
    {
      field: 'image',
      headerName: 'Profile',
      sortable: false,
      width: 90,
      renderCell: (params) => <ProfileImage src={params.row.image} alt={params.row.firstName} />
    },
    {
      field: 'fullName',
      headerName: 'Full name',
      valueGetter: (params) =>
        `${params.row.firstName || ''} ${params.row.middleName || ''}  ${params.row.lastName || ''}`,
      editable: false,
      flex: 1
    },
    {
      field: 'email',
      headerName: 'Email',
      editable: false,
      flex: 1
    },
    {
      field: 'role',
      headerName: 'Role',
      valueGetter: (params) => `${params.row.role.role || ''}`,
      editable: false,
      flex: 1
    },
    {
      field: 'classAttending',
      headerName: 'Class',
      valueGetter: (params) => (params.row.classAttending && `${params.row.classAttending.class || ''}`) || '',
      editable: false,
      flex: 1
    },
    {
      field: 'shift',
      headerName: 'Shift',
      valueGetter: (params) =>
        (params.row.shift &&
          `${params.row.shift.shift || ''} ${params.row.shift.startTime || ''} - ${params.row.shift.endTime || ''}`) ||
        '',
      editable: false,
      flex: 1
    }
  ];

  useEffect(() => {
    loadData();
    loadCurrentUser();
  }, []);

  const loadCurrentUser = () => {
    setTimeout(async () => {
      try {
        const user = await getCurrentLoggedInUser();
        if (isNotBlank(user)) {
          // setCurrentUser(user);
        } else {
          await UsersAPI.logout();
          history.push('/');
        }
      } catch (error) {
        console.log('🪵 : useEffect : error:', error);
        await UsersAPI.logout();
        history.push('/');
      }
    }, 0);
  };

  const loadData = () => {
    setTimeout(async () => {
      try {
        setLoading(true);
        const data = await loadModelData();
        setModelData(data);
        parseRoles(data);
        setLoading(false);
      } catch (error) {
        console.log('🪵 : loadData : error:', error);
        setLoading(false);
      }
    }, 0);
  };

  const loadUsers = async (refresh = false) => {
    !loading && setLoading(true);
    const query = searchString;
    const response = await UsersAPI.search({ query, roleNotEqualTo: studentRole._id }, refresh);
    if (response.success) {
      setUsers(response.users || []);
    } else {
      setUsers([]);
    }
    setLoading(false);
  };

  const parseRoles = (data) => {
    if (isNotBlank(data) && isNotBlank(data.roles)) {
      // const teacherRoleLocal = data.roles.filter((role) => role.role === 'Teacher')[0];
      const studentRoleLocal = data.roles.filter((role) => role.role === 'Student')[0];
      // const otherRoles = data.roles.filter((role) => role.role !== 'Student' && role.role !== 'Teacher');
      // const otherRoleLocal = otherRoles[otherRoles.length - 1];
      setStudentRole(studentRoleLocal);
      // setTeacherRole(teacherRoleLocal);
      // setOtherRole(otherRoleLocal);
    }
  };

  const onClickSearch = () => {
    loadUsers();
  };

  const onClickRow = (content) => {
    const user = content.row;
    openAddUpdateUserDialog(user.role, user);
  };

  const openAddUpdateUserDialog = (role, user = null) => {
    console.log('\n▶️ -> openAddUpdateUserDialog -> user:', user);

    // setActionRole(role);
    // setEditUser(user);
    setShowAddUpdateUserDialog(true);
  };

  const closeAddUpdateUserDialog = (event, reason) => {
    if (reason && reason === 'backdropClick') return;
    setShowAddUpdateUserDialog(false);
    // setActionRole(null);
    // setEditUser(null);
  };

  // const responseCallBack = (response = { success: false, message: '' }) => {
  //   if (response.success) {
  //     closeAddUpdateUserDialog();

  //     setLoading(true);
  //     const user = response.user;
  //     let newUsers = users || [];

  //     if (isBlank(editUser)) {
  //       newUsers = [...newUsers, user];
  //     } else {
  //       if (user.isDeleted) {
  //         newUsers = newUsers.filter((item) => !(item._id === user._id && user.isDeleted));
  //       } else {
  //         newUsers = newUsers.map((u) => (u._id !== user._id ? u : user));
  //       }
  //     }

  //     const userForPage = { page: 0, users: newUsers };
  //     localStorage.setItem(KEYS.USERS, JSON.stringify([userForPage]));
  //     setUsers(newUsers);
  //     setLoading(false);
  //     setEditUser(null);

  //     toast.success(response.message);
  //   } else {
  //     toast.error(response.message);

  //     if (response.registrationCanceled) {
  //       closeAddUpdateUserDialog();
  //     }
  //   }
  // };

  const dataGrid = () => (
    <div style={{ minHeight: 400, width: '100%' }}>
      <DataGrid
        getRowId={(row) => row._id.valueOf()}
        slots={{
          noRowsOverlay: EmptyGridOverlay
        }}
        rows={users}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 13
            }
          }
        }}
        onRowClick={onClickRow}
        pageSizeOptions={[13]}
        sx={GRID_STYLE}
      />
    </div>
  );

  const addUpdateUserDialog = () => {
    return (
      <Modal
        open={showAddUpdateUserDialog}
        onClose={closeAddUpdateUserDialog}
        aria-labelledby='parent-modal-title'
        aria-describedby='parent-modal-description'
      >
        <Box sx={{ ...BOX_STYLE, overflowY: 'auto', maxHeight: '95%' }}>
          <></>
        </Box>
      </Modal>
    );
  };

  const searchOptions = () => (
    <div
      className='d-flex flex-row justify-content-between'
      style={{ height: '60px', marginTop: '10px', marginBottom: '10px' }}
    >
      <TextField
        id='search-employee'
        label='Name, Email, Mobile'
        variant='outlined'
        value={searchString}
        onChange={(event) => {
          const value = event.target.value;
          setSearchString(value || '');
        }}
        onKeyPress={(event) => {
          const value = event.target.value;
          const key = event.key;

          if (key === 'Enter' && isNotBlank(value)) {
            loadUsers();
          }
        }}
        fullWidth
        sx={{
          marginLeft: '0px',
          marginRight: '5px',
          ...autoCompleteCustomStyle
        }}
      />
      {designations && (
        <Autocomplete
          id='designations-autocomplete'
          options={designations}
          getOptionLabel={(option) => option.designation || ''}
          fullWidth
          sx={{
            marginLeft: '5px',
            marginRight: '5px',
            ...autoCompleteCustomStyle
          }}
          renderInput={(params) => <TextField {...params} label='Designation' />}
        />
      )}
      {grades && (
        <Autocomplete
          id='grades-autocomplete'
          options={grades}
          getOptionLabel={(option) => option.employmentGrade || ''}
          fullWidth
          sx={{
            marginLeft: '5px',
            marginRight: '5px',
            ...autoCompleteCustomStyle
          }}
          renderInput={(params) => <TextField {...params} label='Grade' />}
        />
      )}

      <Button
        style={{ marginLeft: '10px', marginRight: '0px', height: '55px' }}
        variant='contained'
        onClick={onClickSearch}
      >
        Search
      </Button>
    </div>
  );

  return (
    <Fragment>
      <div key={'Salary-Distribution'} className='p-3 card w-100'>
        <div className='d-flex flex-column justify-content-between'>
          <h2 style={{ color: 'gray' }}>Salary Distribution</h2>
          {searchOptions()}
          {dataGrid()}
          {addUpdateUserDialog()}
        </div>
      </div>
      <Toaster />
      <LoadingIndicator open={loading} />
    </Fragment>
  );
};

export default SalaryDistributionForm;
